import { IconButton as MuiIconButton } from '@mui/material'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const IconButton = ({ children, variant = 'ghost', loading = false, className, ...props }) => {
  const { disabled } = props
  return (
    <MuiIconButton {...props} className={clsx(variant, className)} disabled={disabled || loading || false}>
      {children}
    </MuiIconButton>
  )
}
IconButton.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['outlined', 'ghost']),
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}
export default IconButton

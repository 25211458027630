import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import '../config/locales/es.json'


export const useTranslation = () => {
  const intl = useIntl()

  const t = useCallback(
    (key, params) => intl.formatMessage({ id: key, defaultMessage: key }, params),
    [intl]
  )

  return t
}

export default useTranslation

import accordion from 'theme/elements/accordion'
import alert from 'theme/elements/alert'
import avatar from 'theme/elements/avatar'
import backdrop from 'theme/elements/backdrop'
import breadcrumbs from 'theme/elements/breadcrumbs'
import button from 'theme/elements/button'
import chip from 'theme/elements/chip'
import dialog from 'theme/elements/dialog'
import divider from 'theme/elements/divider'
import form from 'theme/elements/form'
import grid from 'theme/elements/grid'
import iconButton from 'theme/elements/iconButton'
import list from 'theme/elements/list'
import paper from 'theme/elements/paper'
import stepper from 'theme/elements/stepper'
import table from 'theme/elements/table'
import tabs from 'theme/elements/tabs'
import tooltip from 'theme/elements/tooltip'
import typography from 'theme/elements/typography'
import linearProgress from 'theme/elements/linearProgress'
import dataGrid from 'theme/elements/dataGrid'
import menu from 'theme/elements/menu'

import { mergeDeep } from 'theme/base/utils'

export default mergeDeep(
  accordion,
  alert,
  avatar,
  backdrop,
  breadcrumbs,
  button,
  chip,
  dialog,
  divider,
  form,
  grid,
  iconButton,
  list,
  paper,
  stepper,
  table,
  tabs,
  tooltip,
  typography,
  linearProgress,
  dataGrid,
  menu
)

import communication from 'utils/communication'

const REACT_APP_ATLAS = process.env.REACT_APP_ATLAS_CONECTION
const ATLAS_DIRECTORY =
  window?.ApplicableDirectory?.apiCliklup || process.env.REACT_APP_API_ATLAS_V2_DIR

  export const postReactionMetrics = async (data) => {
    return communication.post(
      `${REACT_APP_ATLAS}/${ATLAS_DIRECTORY}`, `/metricas/reacciones`,
      data
    );
  };



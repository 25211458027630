import { TechnicalAssistanceFormView } from 'ui'
import { Contact } from 'forms'

export const ContactFormView = () => {
  return (
    <TechnicalAssistanceFormView
      title={'contact_form:input:contact_with_us'}
      subtitle={'contact_form:subtitle:any_doubts'}
    >
      <Contact></Contact>
    </TechnicalAssistanceFormView>
  )
}

export default ContactFormView

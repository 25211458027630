import { useState, useMemo } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useTranslation, useMessageSnackbar } from 'hooks'
import { messageErrors } from 'utils'
import { CRITICALS_ERRORS } from 'config'

const APP_URL_BASE = process.env.REACT_APP_GALAXY_URL

export const ErrorInterceptor = () => {
  const navigate = useNavigate()
  const t = useTranslation()
  const showSnackbar = useMessageSnackbar()

  const interceptorUse = () => {
    axios.interceptors.response.use(null, error => {
      const { response } = error

      const errorType = {
        type: 'snackbar',
        code: null,
        messageKey: null,
        action: false,
        actionText: null,
        actionLink: null,
      }

      let msgError = null
      const msgeError = messageErrors(error)
      msgError = msgeError.messageKey
      const msgCode = msgeError.error

      if (!response) {
        // network error
        let isCriticalError = false
        CRITICALS_ERRORS.forEach(domain => {
          if (error?.config?.url.includes(domain)) {
            isCriticalError = true
          }
        })

        if (isCriticalError === true) {
          errorType.type = 'page'
          errorType.code = 'contactUs'
        } else {
          errorType.type = 'serverErrorSnackbar'
        }
      }

      if (response) {
        switch (response?.status) {
          case 401: //Unauthorized
            errorType.type = 'page'
            break
          case 400: // Bad Request
            errorType.type = 'snackbar'
            break
          case 403: // Forbidden
            errorType.type = 'snackbar'
            if (!localStorage.getItem('sessionToken')) {
              window.location.replace(APP_URL_BASE)
            }
            break
          case 404: //  Not found
            errorType.type = 'snackbar'
            break
          case 500: // Internal Server Error
            errorType.type = 'snackbar'
            break
          default:
            // errores controlados
            if (msgError) {
              errorType.type = 'snackbar'
            }
            break
        }
      }
      if (response?.status) {
        errorType.code = response?.status
        errorType.messageKey = msgError
      }

      if (msgError) {
        console.error('Error: ', errorType.code, ' code: ', msgCode, ' message key: ', errorType.messageKey)
      }

      switch (errorType.type) {
        case 'page':
          navigate('/error', { state: { code: errorType.code } })
          break
        case 'snackbar':
          showSnackbar({ type: 'error', message: t(errorType.messageKey) })
          break
        case 'serverErrorSnackbar':
          showSnackbar({ type: 'serverError', message: t('error_interceptor:internal_server_error') })
          break
        default:
          console.error(`Error: ${t(errorType.messageKey)}`)
          break
      }

      return Promise.reject(error)
    })
  }

  const interceptor = useMemo(() => interceptorUse, [])

  useState(() => {
    interceptor()
  }, [])
}
export default ErrorInterceptor

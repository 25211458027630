import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { TextField, Autocomplete, Paper, Box, Tooltip } from '@mui/material'
import clsx from 'clsx'
import useStyles from './styles'


const AUTOCOMPLETE_EMPTY_OPTION = [{ uuid: '', nombre: '--' }]
const SELECT_EMPTY_OPTION = '--'

const AutocompleteSelect = ({
  id,
  name,
  label,
  sx,
  options,
  value,
  handleChange,
  disabled = false,
  required = false,
  type,
  emptyOption = true,
  helperText,
  handleBlur,
  error = false,
}) => {
  const classes = useStyles()
  const [fullOptions, setFullOptions] = useState(AUTOCOMPLETE_EMPTY_OPTION || [])
  const [inputValue, setInputValue] = useState('')
  useEffect(() => {
    if (!value) {
      setInputValue('')
      return
    }
    setInputValue(value?.nombre)
  }, [value])

  useEffect(() => {
    if (options) {
      if (emptyOption) {
        setFullOptions(AUTOCOMPLETE_EMPTY_OPTION.concat(options) || [])
      } else {
        setFullOptions(options || [])
      }
    }
  }, [options])
  return (
    <Autocomplete
      disableClearable
      openOnFocus
      disablePortal
      sx={sx}
      value={value || null}
      inputValue={(inputValue === SELECT_EMPTY_OPTION ? '' : inputValue) || ''}
      options={fullOptions}
      filterOptions={(fullOptions, state) => fullOptions}
      getOptionLabel={option => option.nombre}
      isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
      PaperComponent={({ children }) => <Paper className={classes.paper}>{children}</Paper>}
      onChange={handleChange}
      disabled={disabled}
      className={clsx(classes.autocomplete, value && !error && 'hasValue')}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <Tooltip title={option?.nombre || ''}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{option?.nombre}</span>
          </Tooltip>
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          id={id}
          name={name}
          label={label}
          required={required}
          variant="filled"
          onBlur={handleBlur}
          error={error}
          helperText={helperText}
          className={clsx(classes.input, type === 'compact' ? 'compact' : '')}
        />
      )}
    />
  )
}
AutocompleteSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  sx: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  emptyOption: PropTypes.bool,
  helperText: PropTypes.string,
  handleBlur: PropTypes.func,
  error: PropTypes.bool,
}
export default AutocompleteSelect

import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box } from '@mui/material'
import useTranslation from 'hooks/useTranslation'
import ErrorController from 'ui/modules/errorController/errorController'
import ShinyButton from 'ui/atoms/buttons/buttonShiny'
import TextAreaAutosize from 'ui/atoms/textareaAutosize'
import globalUseStyles from 'theme/base/globalUseStyles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

export const ContactForm = ({
  formState,
  formTouched,
  handleSubmit,
  onChangeValueState,
  isLoading,
  hasFormChange,
  onChangeTouched,
}) => {
  const t = useTranslation()
  const globalClasses = globalUseStyles()

  const validationSchema = Yup.object({
    descripcion_contacto: Yup.string().nullable().required(),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      descripcion_contacto: formState?.descripcion_contacto || '',
    },
    initialErrors: {},
    initialTouched: {
      descripcion_contacto: formTouched?.descripcion_contacto || false,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSubmit()
    },
  })

  return (
    <Box sx={{ width: '100%' }} className={clsx(isLoading ? globalClasses.disabled : null)}>
      <ErrorController type="section" size="medium" isCard={true}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start">
            <Grid item xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextAreaAutosize
                label={t('contact_form:label:description')}
                placeholder={t('contact_form:placeholder:description')}
                id="descripcion_contacto"
                name="descripcion_contacto"
                minRows={10}
                value={formik?.values?.descripcion_contacto}
                onChange={e => {
                  formik?.setFieldValue('descripcion_contacto', e.target.value)
                  onChangeValueState({
                    field: 'descripcion_contacto',
                    value: e.target.value,
                  })
                  setTimeout(() => {
                    formik?.validateForm()
                  }, 500)
                }}
                onBlur={() => {
                  onChangeTouched({ field: 'descripcion_contacto' })
                  formik?.validateForm()
                }}
                className={clsx(
                  formik?.values?.descripcion_contacto && !formik.errors.descripcion_contacto ? 'hasValue' : '',
                )}
                autoComplete="off"
                type="compact"
              />
            </Grid>
            <Grid container item justifyContent="flex-end" alignItems="center" marginTop={{ xs: '32px' }}>
              <Grid item>
                <ShinyButton
                  type="submit"
                  loading={isLoading || false}
                  disabled={isLoading || !hasFormChange || !formik.isValid}
                >
                  {t('button:submit')}
                </ShinyButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </ErrorController>
    </Box>
  )
}

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  formState: PropTypes.object,
  onChangeValueState: PropTypes.func,
  isLoading: PropTypes.bool,
  hasFormChange: PropTypes.bool,
  formTouched: PropTypes.object,
  onChangeTouched: PropTypes.func,
}

export default ContactForm

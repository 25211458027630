import { useContext } from 'react'
import { useMutation } from 'react-query'
import { postContactUs, postRequestFuncionality, postReportError } from './communication.service'
import { useMessageSnackbar, useTranslation } from 'hooks'
import { DELAY_SNACKBAR } from 'config/constantsApp'
import GlobalContextProvider from 'context/global/GlobalContextProvider'
import { useNavigate } from 'react-router-dom'

export const useAddNewContactUs = () => {
  const t = useTranslation()
  const showSnackbar = useMessageSnackbar()
  const { setIsSuccessForm } = useContext(GlobalContextProvider)
  const navigate = useNavigate()

  return useMutation(data => postContactUs(data).then(res => res), {
    onSuccess: res => {
      navigate('/init')
      setIsSuccessForm(true)
      showSnackbar({
        type: 'success',
        message: t('common:forms:msg:success'),
        delay: DELAY_SNACKBAR,
      })
    },
    onError: er => {
      setIsSuccessForm(false)
    },
  })
}

export const useAddNewRequestFuncionality = () => {
  const t = useTranslation()
  const showSnackbar = useMessageSnackbar()
  const { setIsSuccessForm } = useContext(GlobalContextProvider)
  const navigate = useNavigate()

  return useMutation(
    ({ asunto, descripcion, adjunto }) => postRequestFuncionality({ asunto, descripcion, adjunto }).then(res => res),
    {
      onSuccess: res => {
        navigate('/init')
        setIsSuccessForm(true)
        showSnackbar({
          type: 'success',
          message: t('common:forms:msg:success'),
          delay: DELAY_SNACKBAR,
        })
      },
      onError: er => {
        setIsSuccessForm(false)
      },
    },
  )
}

export const useAddNewReportError = () => {
  const t = useTranslation()
  const showSnackbar = useMessageSnackbar()
  const { setIsSuccessForm } = useContext(GlobalContextProvider)
  const navigate = useNavigate()

  return useMutation(
    ({ asunto, descripcion, tipo_problema, adjunto }) =>
      postReportError({ asunto, descripcion, tipo_problema, adjunto }).then(res => res),
    {
      onSuccess: res => {
        navigate('/init')
        setIsSuccessForm(true)
        showSnackbar({
          type: 'success',
          message: t('common:forms:msg:success'),
          delay: DELAY_SNACKBAR,
        })
      },
      onError: er => {
        setIsSuccessForm(false)
      },
    },
  )
}

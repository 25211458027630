import React from 'react'
import useErrorForm from 'forms/errorForm/useErrorForm'
import { ErrorForm } from 'ui'
import { equals } from 'ramda'

export const Error = () => {
  const {
    formTouched,
    formState,
    handleSubmit,
    onChangeValueState,
    defaultFormState,
    onChangeTouched,
    initialValues,
    uploadFile,
    resetFile,
    addNewReportError,
  } = useErrorForm()

  return (
    <>
      <ErrorForm
        formState={formState}
        handleSubmit={handleSubmit}
        formTouched={formTouched}
        onChangeValueState={onChangeValueState}
        hasFormChange={!equals(formState, defaultFormState)}
        onChangeTouched={onChangeTouched}
        initialValues={initialValues}
        uploadFile={uploadFile}
        resetFile={resetFile}
        isLoading={addNewReportError?.isLoading}
      />
    </>
  )
}

export default Error

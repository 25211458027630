import { useTranslation, useMessageSnackbar } from 'hooks'

export const useCheckEmptyForm = () => {
  const t = useTranslation()
  const showSnackbar = useMessageSnackbar()

  const isEmptyForm = data => {
    const isEmptyObject = isEmpty(data)
    if (isEmptyObject) {
      showSnackbar({ type: 'error', message: t('common:forms_empty') })
    }
    return isEmptyObject
  }

  return isEmptyForm
}

export default useCheckEmptyForm

const isEmpty = (obj, empty = true) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] !== null) {
      if (typeof obj[key] === 'object') {
        empty = isEmpty(obj[key], empty)
      } else {
        empty = empty && obj[key].length === 0
      }

      if (!empty) {
        return empty
      }
    }
  })

  return empty
}

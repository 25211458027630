import { TechnicalAssistanceFormView } from 'ui'
import { Function } from 'forms'

export const FunctionFormView = () => {
  return (
    <TechnicalAssistanceFormView
      title={'function_form:input:ask_for_function'}
      subtitle={'error_form:input:description'}
    >
      <Function></Function>
    </TechnicalAssistanceFormView>
  )
}

export default FunctionFormView

import PropTypes from 'prop-types'
import { Grid, Card, CardContent, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Avatar, ButtonEmoji } from 'ui'

export const SupportArticlesCard = ({
  id,
  titulo,
  subtitulo,
  coleccion,
  usuario_nombre,
  usuario_foto,
  resumen,
  contenido,
}) => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <Grid container direction="row" justifyContent={'center'} minHeight={'100%'}>
      <Card
        sx={
          smDown
            ? {
                flexGrow: '1',
                paddingLeft: '110px',
                paddingTop: '50px',
                paddingRight: '110px',
                paddingBottom: '24px',
              }
            : {
                flexGrow: '1',
                paddingLeft: '24px',
                paddingTop: '16px',
                paddingRight: '36px',
                paddingBottom: '18px',
              }
        }
      >
        <CardContent mb={3.3} sx={{ padding: '0px' }}>
          <Typography variant="subtitle2" mb={2} ml={1}>
            {coleccion}
          </Typography>
          <Typography variant="h4" ml={1} marginBottom="4.5px">
            {titulo}
          </Typography>
          <div style={{ marginLeft: '8px' }}>
            <Typography variant="subtitle3" sx={{ padding: '0px' }}>
              {subtitulo}
            </Typography>
          </div>
          <div style={{ marginLeft: '8px' }}>
            <Typography variant="subtitle3" sx={{ padding: '0px' }}>
              {resumen}
            </Typography>
          </div>
          <Grid container mt={1.5}>
            <Grid item ml={1}>
              <Avatar avatarURL={usuario_foto} />
            </Grid>
            <Grid item mt={1.2} ml={1}>
              <Typography variant="subtitle1">{usuario_nombre}</Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Typography paragraph mb={2.81}>
            {contenido}
          </Typography>
          <ButtonEmoji idArticulo={id} />
        </CardContent>
      </Card>
    </Grid>
  )
}

SupportArticlesCard.propTypes = {
  id: PropTypes.string,
  titulo: PropTypes.string,
  subtitulo: PropTypes.string,
  resumen: PropTypes.string,
  contenido: PropTypes.string,
  coleccion: PropTypes.string,
  usuario_nombre: PropTypes.string,
  usuario_foto: PropTypes.string,
}

export default SupportArticlesCard

import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

export const TextOverflow = ({ lines = 1, variant = 'subtitle1', children, sx }) => {
  return (
    <>
      <Typography
        variant={variant}
        component="span"
        sx={{
          ...{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: `${lines}`,
          },
          ...sx,
        }}
      >
        {children}
      </Typography>
    </>
  )
}

TextOverflow.propTypes = {
  lines: PropTypes.number,
  variant: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
}
export default TextOverflow

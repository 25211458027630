import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  iconButton: {
    '&:hover': {
      background: `${theme.palette.primary.lighter} !important`,
    },
  },
  icon: {
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  textContainer: {
    display: 'flex',
    minHeight: '30px',
    '&.underSm': {
      minHeight: '20px',
    },
  },
  text: {
    paddingTop: '6px',
    '&.underSm': {
      paddingTop: '2px',
    },
  },
}))

export default useStyles

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    '&.xs': {
      width: '24px !important',
      height: '24px !important',
    },
    '& > span': {
      marginTop: '2px',
    },
  },
  text: {
    color: `${theme.palette.primary.main} !important`,
  },
  icon: {
    '&.large': {
      width: '140px',
      height: '140px',
    },
    '&.medium': {
      width: '50px',
      height: '50px',
    },
  },
}))

export default useStyles

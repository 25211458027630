import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    '&.underMd': {
      width: '435px',
      '& .MuiDrawer-paper': {
        width: '435px',
      },
    },
    '&.underSm': {
      width: '304px',
      '& .MuiDrawer-paper': {
        width: '304px',
      },
    },
    '& .MuiDrawer-paper': {
      zIndex: '10000',
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
    },
  },
  list: {
    '& a:hover': {
      background: theme.palette.primary.hover,
    },
    '& .MuiButtonBase-root': {
      background: 'transparent !important',
      margin: '0 0 0 40px',
      padding: '0px',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& .MuiListItemText-root': {
      color: theme.palette.text.primary,
    },
    '&.aboveSm': {
      '& a.MuiLink-root': {
        height: '90px',
        display: 'flex',
      },
      '& a.MuiLink-root li': {
        height: '90px',
        display: 'flex',
      },
    },
    '&.underSm': {
      '& a.MuiLink-root': {
        height: '48px',
        display: 'flex',
      },
      '& a.MuiLink-root li': {
        height: '48px',
        display: 'flex',
      },
    },
  },
}))

export default useStyles

import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, Tooltip, TextField, Box, IconButton } from '@mui/material'
import useTranslation from 'hooks/useTranslation'
import ErrorController from 'ui/modules/errorController/errorController'
import ShinyButton from 'ui/atoms/buttons/buttonShiny'
import TextAreaAutosize from 'ui/atoms/textareaAutosize'
import InputFile from 'ui/atoms/inputs/inputFile'
import globalUseStyles from 'theme/base/globalUseStyles'
import { UploadSimple } from 'phosphor-react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

export const FunctionForm = ({
  formState,
  formTouched,
  handleSubmit,
  onChangeValueState,
  isLoading,
  hasFormChange,
  onChangeTouched,
  isUploadingFile,
  uploadFile,
  resetFile,
}) => {
  const t = useTranslation()
  const globalClasses = globalUseStyles()
  const inputRef = createRef()

  const validationSchema = Yup.object({
    asunto: Yup.string().required(t('form:required_field')).nullable(),
    descripcion: Yup.string().required(t('form:required_field')).nullable(),
    adjunto_nombre: Yup.string().nullable(),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      asunto: formState?.asunto || '',
      descripcion: formState?.descripcion || '',
      adjunto_nombre: formState?.adjunto_nombre || '',
    },
    initialErrors: {},
    initialTouched: {
      asunto: formTouched?.asunto || false,
      descripcion: formTouched?.descripcion || false,
      adjunto_nombre: formTouched?.adjunto_nombre || false,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSubmit()
    },
  })

  const handleClickAddFile = () => {
    inputRef.current.click()
  }

  const onSelectFile = e => {
    uploadFile(e)
    const nameBinarySubi = e.target.files[0].name
    formik?.setFieldValue('adjunto_nombre', nameBinarySubi)
    onChangeTouched({ field: 'adjunto_nombre' })
    setTimeout(() => {
      formik?.validateForm()
    }, 500)
  }

  return (
    <Box sx={{ width: '100%' }} className={clsx(isLoading ? globalClasses.disabled : null)}>
      <ErrorController type="section" size="medium" isCard={true}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start">
            <Grid item xss={12} xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                label={t('form:input:subject')}
                variant="filled"
                id="asunto"
                name="asunto"
                required={true}
                value={formik?.values?.asunto}
                onBlur={() => {
                  onChangeTouched({ field: 'asunto' })
                  formik?.validateForm()
                }}
                onChange={e => {
                  formik?.setFieldValue('asunto', e.target.value)
                  onChangeValueState({ field: 'asunto', value: e.target.value })
                  setTimeout(() => {
                    formik?.validateForm()
                  }, 500)
                }}
                className={clsx('compact', formik?.values?.asunto && !formik?.errors?.asunto && 'hasValue')}
                error={Boolean(formik?.touched?.asunto && formik?.errors?.asunto)}
                helperText={formik?.touched?.asunto ? formik?.errors?.asunto : ''}
                autoComplete="off"
              />
            </Grid>
            <Grid item xss={12} xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextAreaAutosize
                placeholder={t('function_form:input:ask_for_function')}
                label={t('form:input:description')}
                id="descripcion"
                name="descripcion"
                value={formik?.values?.descripcion}
                onBlur={() => {
                  onChangeTouched({ field: 'descripcion' })
                  formik?.validateForm()
                }}
                minRows={5}
                onChange={e => {
                  formik?.setFieldValue('descripcion', e.target.value)
                  onChangeValueState({
                    field: 'descripcion',
                    value: e.target.value,
                  })
                  setTimeout(() => {
                    formik?.validateForm()
                  }, 500)
                }}
                className={clsx(formik?.values?.descripcion && !formik.errors.descripcion ? 'hasValue' : '')}
                autoComplete="off"
                type="compact"
              />
            </Grid>
            <Grid item xss={12} xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex' }}>
              <InputFile
                label={t('error_form:label:file')}
                id="adjunto_nombre"
                name="adjunto_nombre"
                type="compact"
                disabled={isUploadingFile}
                isLoadingFile={isUploadingFile}
                value={formik?.values?.adjunto_nombre}
                onBlur={() => {
                  onChangeTouched({ field: 'adjunto_nombre' })
                  formik?.validateForm()
                }}
                onDeleteFile={e => {
                  resetFile()
                  formik?.setFieldValue('adjunto_nombre', '')
                  onChangeTouched({ field: 'adjunto_nombre' })
                  setTimeout(() => {
                    formik?.validateForm()
                  }, 500)
                }}
                onChange={e => {
                  setTimeout(() => {
                    formik?.validateForm()
                  }, 500)
                }}
                className={clsx(
                  'compact',
                  formik?.values?.adjunto_nombre && !formik?.errors.adjunto_nombre && 'hasValue',
                )}
                error={Boolean(formik?.touched?.adjunto_nombre && formik?.errors?.adjunto_nombre)}
                helperText={formik?.touched?.adjunto_nombre ? formik?.errors?.adjunto_nombre : ''}
                required={false}
              />
              <Tooltip title={t('error_form:label:file')} enterDelay={500}>
                <span>
                  <IconButton size="medium" sx={{ marginTop: '19px' }} onClick={handleClickAddFile}>
                    <UploadSimple size={24} />
                  </IconButton>
                  <input
                    type="file"
                    onChange={onSelectFile}
                    style={{ display: 'none' }}
                    ref={inputRef}
                    onClick={e => (e.target.value = null)}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Grid item>
                <ShinyButton
                  type="submit"
                  loading={isLoading || false}
                  disabled={isLoading || !hasFormChange || !formik.isValid || isUploadingFile}
                >
                  {t('button:submit')}
                </ShinyButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </ErrorController>
    </Box>
  )
}

FunctionForm.propTypes = {
  handleSubmit: PropTypes.func,
  formState: PropTypes.object,
  isLoading: PropTypes.bool,
  hasFormChange: PropTypes.bool,
  onChangeValueState: PropTypes.func,
  formTouched: PropTypes.object,
  onChangeTouched: PropTypes.func,
  isUploadingFile: PropTypes.bool,
  resetFile: PropTypes.func,
  uploadFile: PropTypes.func,
}

export default FunctionForm

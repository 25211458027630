import PropTypes from 'prop-types'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'hooks'
import { useCollectionsQuery } from 'services'
import { SupportCard } from 'ui'
import { FileText } from 'phosphor-react'

export const SupportView = () => {
  const t = useTranslation()
  const theme = useTheme()
  const smallUp = useMediaQuery(theme.breakpoints.up('sm'))

  const { collectionsData } = useCollectionsQuery({ hardLoad: false })

  return (
    <Grid container mt={{ xl: '32px', xs: '40px' }} direction={smallUp ? 'row' : 'column'}>
      <Grid
        container
        justifyContent="center"
        alignContent="flex-start"
        columns={{ md: 12, lg: 12, xl: 16 }}
        paddingRight={{
          xs: '16px',
          sm: '40px',
          md: '88px',
          lg: '88px',
          xl: '88px',
        }}
        paddingLeft={{
          xs: '16px',
          sm: '40px',
          md: '88px',
          lg: '88px',
          xl: '92px',
        }}
      >
        <Grid item mb={{ xl: '32px', xs: '32px', sm: '32px', md: '32px' }} lg={12} xl={12} sm={12} xs={12}>
          <Typography variant={smallUp ? 'h3' : 'h1Mobile'} align="center">
            {t('support_view:title:support_items')}
          </Typography>
        </Grid>
        <Grid item mb={{ xl: '32px', xs: '32px', sm: '32px', md: '32px' }} lg={12} xl={12} sm={12} xs={12}>
          <Typography variant={smallUp ? 'h6' : 'h1Mobile'} align="center">
            {t('support_view:subtitle:more_about_galaxy')}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems={'center'}
        columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }}
        columnSpacing={{
          xs: '8px',
          sm: '16px',
          md: '16px',
          lg: '16px',
          xl: '16px',
        }}
        rowSpacing={4}
        paddingRight={{
          xs: '16px',
          sm: '40px',
          md: '80px',
          lg: '80px',
          xl: '88px',
        }}
        paddingLeft={{
          xs: '16px',
          sm: '40px',
          md: '80px',
          lg: '80px',
          xl: '88px',
        }}
      >
        {collectionsData?.map(list => {
          const { id, nombre } = list
          return (
            <Grid item key={id} xs={4} sm={8} md={6} lg={4} xl={5.5} mb={{ sm: 1.2 }} mt={{ sm: 2 }}>
              <SupportCard nav={`/support-collection/${id}`} isLoading={true} title={nombre} icon={<FileText />} />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

SupportView.propTypes = {
  data: PropTypes.array,
}

export default SupportView

import palette from 'theme/base/palette'

const breadcrumbs = {
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          paddingTop: '16px',
          '& .MuiTypography-body1': {
            fontWeight: 400,
          },
          '& .MuiButtonBase-root': {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        separator: {
          color: palette.neutral.main,
        },
      },
    },
  },
}

export default breadcrumbs

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.lighter,
  },
  mainContainer: {
    marginTop: '64px',
    minHeight: 'calc(100vh - 107px)',
    '&.separator': {
      paddingTop: '24px',
    },
  },
  breadcrumbContainer: {
    height: '40px',
    marginTop: '16px',
    marginBottom: '8px',
  },
}))

export default useStyles

export const breakpoints = {
  values: {
    xss: 0,
    xs: 360,
    sm: 720,
    md: 1024,
    lg: 1440,
    xl: 1920,
  },
}

export default breakpoints

export default {
  // Errores generales
  'error.default': { messageKey: 'errors:msg:default_error' },
  'error.net_error': { messageKey: 'errors:msg:error.net' },
  //General exception
  'error.parametro.general.obligatorio_no_valido': { messageKey: 'errors:msg:mandatory_parameter_not_valid' },
  //CheckPropiedadesException
  'general.peticion.archivo.no.valido': { messageKey: 'errors:msg:file_not_valid' },
  'general.peticion.archivo.tamanno.no.valido': { messageKey: 'errors:msg:file_size_not_valid' },
  'general.peticion.archivo.imagen.no.valida': { messageKey: 'errors:msg:image_not_valid' },
  'general.peticion.parametros.vacio': { messageKey: 'errors:msg:empty_parameters' },
  'general.peticion.parametros.parametro.obligatorio': { messageKey: 'errors:msg:mandatory_parameters' },
  'general.peticion.parametros.parametro.no.valido': { messageKey: 'errors:msg:parameter_not_valid' },
  //ComunicacionException
  'error.comunicacion.apis': { messageKey: 'errors:msg:comunication_apis' },
  'error.comunicacion.configuracion': { messageKey: 'errors:msg:comunication_configuration' },
  //ConfiguracionException
  'error.configuracion.url': { messageKey: 'errors:msg:configuration_url' },
  'error.configuracion.plantilla.listado': { messageKey: 'errors:msg:configuration_list_template' },
  //InvalidContentTypeException
  'errores.general.content-type': { messageKey: 'errors:msg:content-type' },
  //TransformacionException
  'error.transformacion.url_api': { messageKey: 'errors:msg:transformation_error_url_api' },
  'error.transformacion.parametro.formato': { messageKey: 'errors:msg:transformation_param_not_valid' },
  'error.transformacion.comunicacion.servicio': { messageKey: 'errors:msg:tansformation_comunication_service' },
  // Errores de estado
  'error.400': { messageKey: 'errors:msg:400_bad_request' },
  'error.401': { messageKey: 'errors:msg:401_authorization_required' },
  'error.403': { messageKey: 'errors:msg:403_forbiden' },
  'error.404': { messageKey: 'errors:msg:404_not_found' },
  'error.500': { messageKey: 'errors:msg:500_internal_server_error' },
  // ERRORES CONTROLADOS BACKEND
  // Autenticacion (api-identidades)
  'errores.autenticacion.sesion.no_valida': { messageKey: 'errors:msg:invalid_session' },
  'errores.autenticacion.credenciales': { messageKey: 'errors:msg:invalid_credentials' },
  'errores.autenticacion.sesion.csrf': { messageKey: 'errors:msg:invalid_csrf' },
  'errores.parametros': { messageKey: 'errors:msg:parameters_not_valid' },
  'errores.parametros.login_challenge': { messageKey: 'errors:msg:invalid_login_session' },
}

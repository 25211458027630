import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Skeleton,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { TextOverflow } from 'ui'
import { Plus, Minus } from 'phosphor-react'
import palette from 'theme/base/palette'
import clsx from 'clsx'

export const FaqAccordion = ({ data, isLoading }) => {
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()
  const smallUp = useMediaQuery(theme.breakpoints.up('sm'))

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      {isLoading === false &&
        data?.map(pregunta => {
          const { id, titulo, descripcion } = pregunta
          return (
            <Accordion
              expanded={expanded === id}
              key={id}
              onChange={handleChange(id)}
              sx={{
                '&:last-child': {
                  marginBottom: '0px',
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === id ? (
                    <Minus size={'24px'} weight="bold" style={{ color: palette.primary.main, marginTop: '-8px' }} />
                  ) : (
                    <Plus size={'24px'} weight="bold" style={{ color: palette.primary.main }} />
                  )
                }
                aria-controls="panel-content"
                id="panel-header"
                className={clsx(smallUp === false && 'smallSm')}
                sx={{
                  '&.smallSm': {
                    padding: '13px 40px 13px 40px',
                  },
                  '& .MuiAccordionSummary-content': {
                    marginRight: '15px',
                  },
                  '&.Mui-expanded': {
                    [theme.breakpoints.down('sm')]: {
                      paddingBottom: '0',
                    },
                  },
                  alignItems: expanded === id ? 'baseline' : 'center',
                }}
              >
                <Tooltip title={titulo || ''} enterDelay={300} enterNextDelay={300}>
                  <span>
                    <TextOverflow variant={smallUp ? 'h5' : 'h3Mobile'} lines={expanded === id ? 6 : 2}>
                      {titulo}
                    </TextOverflow>
                  </span>
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails
                mb={4}
                sx={{
                  '& p': {
                    margin: '0',
                  },
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '12px',
                  },
                }}
              >
                <ReactMarkdown>{descripcion}</ReactMarkdown>
              </AccordionDetails>
            </Accordion>
          )
        })}
      {isLoading === true && (
        <>
          <Box>
            <Skeleton
              className={clsx(smallUp === false && 'smallSm')}
              sx={{
                '&.smallSm': { height: '50px' },
                height: '104px',
                marginBottom: '16px',
                transform: 'none',
                borderRadius: '12px',
              }}
            />
            <Skeleton
              className={clsx(smallUp === false && 'smallSm')}
              sx={{
                '&.smallSm': { height: '50px' },
                height: '104px',
                marginBottom: '16px',
                transform: 'none',
                borderRadius: '12px',
              }}
            />
            <Skeleton
              className={clsx(smallUp === false && 'smallSm')}
              sx={{
                '&.smallSm': { height: '50px' },
                height: '104px',
                marginBottom: '16px',
                transform: 'none',
                borderRadius: '12px',
              }}
            />
            <Skeleton
              className={clsx(smallUp === false && 'smallSm')}
              sx={{
                '&.smallSm': { height: '50px' },
                height: '104px',
                marginBottom: '16px',
                transform: 'none',
                borderRadius: '12px',
              }}
            />
          </Box>
        </>
      )}
    </>
  )
}

FaqAccordion.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
}

export default FaqAccordion

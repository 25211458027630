import { createTheme } from '@mui/material/styles'

const defaultTheme = createTheme()

const dialog = {
  components: {
    MuiDialog: {},
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: defaultTheme.spacing(4, 4, 2),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(2, 4),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(2, 4, 4),
        },
      },
    },
  },
}

export default dialog

import { CheckCircle, Info, Warning } from 'phosphor-react'
import palette from 'theme/base/palette'

const alert = {
  props: {
    MuiAlert: {
      styleOverrides: {
        elevation: 1,
        iconMapping: {
          error: <Warning />,
          info: <Info />,
          success: <CheckCircle />,
        },
      },
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '& .MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
          },
        },
        standardError: {
          backgroundColor: palette.error.lighter,
          color: palette.error.dark,
          '& .MuiAlert-icon': {
            color: palette.error.main,
          },
        },
        standardInfo: {
          backgroundColor: palette.primary.lighter,
          color: palette.primary.dark,
          '& .MuiAlert-icon': {
            color: palette.primary.main,
          },
        },
        standardSuccess: {
          backgroundColor: palette.success.lighter,
          color: palette.success.dark,
          '& .MuiAlert-icon': {
            color: palette.success.main,
          },
        },
      },
    },
  },
}

export default alert

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: '250px',
  },
  input: {
    '& label': {
      textAlign: 'left !important',
    },
  },
}))

export default useStyles

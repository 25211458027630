import { createTheme } from '@mui/material/styles'
import palette from 'theme/base/palette'

const defaultTheme = createTheme()

const accordion = {
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: defaultTheme.spacing(2),
          "&:before": {
            display: "none",
          },
        },
        rounded: {
          borderRadius: "12px !important",
          "&.Mui-expanded": {
            border: `2px solid ${palette.primary.main} `,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingTop: "32px",
          paddingLeft: "40px",
          paddingBottom: "32px",
          paddingRight: "40px",
          borderRadius: "12px !important",

          "&:hover": {
            backgroundColor: palette.primary.hover,
            borderRadius: "12px !important",
            "&.Mui-expanded": {
              backgroundColor: "transparent",

            },
          },
          "&.Mui-expanded": {
            paddingBottom: "8px",
          }
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            display: "block",
            margin: defaultTheme.spacing(0, 0, 0),
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: "block",
          marginTop: "0px",
          paddingTop: "0px",
          paddingLeft: "40px",
          paddingBottom: "30px",
          paddingRight: "52px"
        },
      },
    },
  },
};

export default accordion

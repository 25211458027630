import React from 'react'
import useFunctionForm from 'forms/functionForm/useFunctionForm'
import { FunctionForm } from 'ui'
import { equals } from 'ramda'

export const Function = () => {
  const {
    formTouched,
    formState,
    handleSubmit,
    onChangeValueState,
    defaultFormState,
    addNewResquestFuncionality,
    onChangeTouched,
    uploadFile,
    resetFile,
  } = useFunctionForm()

  return (
    <>
      <FunctionForm
        formState={formState}
        handleSubmit={handleSubmit}
        formTouched={formTouched}
        onChangeValueState={onChangeValueState}
        hasFormChange={!equals(formState, defaultFormState)}
        isLoading={addNewResquestFuncionality?.isLoading}
        onChangeTouched={onChangeTouched}
        uploadFile={uploadFile}
        resetFile={resetFile}
      />
    </>
  )
}

export default Function

import PropTypes from 'prop-types'
import ErrorBoundary from 'ui/modules/errorController/errorBoundary'

export const ErrorController = ({
  type,
  iconSize,
  showText,
  showTooltip,
  isExternalError,
  size = 'large',
  isCard = false,
  isOpen = false,
  onClose,
  dialogTitle = '',
  sx,
  children,
  isFullScreen = false,
}) => {
  return (
    <>
      <ErrorBoundary
        type={type}
        iconSize={iconSize}
        showText={showText}
        showTooltip={showTooltip}
        isExternalError={isExternalError}
        size={size}
        isCard={isCard}
        isOpen={isOpen}
        dialogTitle={dialogTitle}
        onClose={onClose}
        sx={sx}
        isFullScreen={isFullScreen}
      >
        {children}
      </ErrorBoundary>
    </>
  )
}

ErrorController.propTypes = {
  type: PropTypes.oneOf(['page', 'section', 'dialog', 'icon']).isRequired,
  iconSize: PropTypes.number,
  showText: PropTypes.bool,
  showTooltip: PropTypes.bool,
  isExternalError: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isCard: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  sx: PropTypes.object,
  dialogTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  isFullScreen: PropTypes.bool,
}

export default ErrorController

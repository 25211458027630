import React, { useState } from 'react'
import PropTypes from 'prop-types'
const GlobalContext = React.createContext({})

export function GlobalContextProvider({ children }) {
  const [clickupCode, setClickupCode] = useState(null)
  const [version, setVersion] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [id, setId] = useState(null)
  const [dataBreadCrumb, setDataBreadCrumb] = useState(null)
  const [showBreadCrumb, setShowBreadCrumb] = useState(null)
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false)
  const [isSuccessForm, setIsSuccessForm] = useState(false)

  return (
    <GlobalContext.Provider
      value={{
        id,
        setId,
        clickupCode,
        setClickupCode,
        version,
        setVersion,
        isLoading,
        setIsLoading,
        dataBreadCrumb,
        setDataBreadCrumb,
        showBreadCrumb,
        setShowBreadCrumb,
        isMainMenuOpen,
        setIsMainMenuOpen,
        isSuccessForm,
        setIsSuccessForm,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
export default GlobalContext

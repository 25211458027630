import communication from 'utils/communication'

const ACCOUNT_BASE_URL = process.env.REACT_APP_API_UCS_INDIVIDUAL
const ACCOUNT_DIRECTORY =
  window?.ApplicableDirectory?.apiUCSIndividual || process.env.REACT_APP_API_UCS_INDIVIDUAL_DEFAULT_DIR
const ENTITIES_BASE_URL = process.env.REACT_APP_API_IDENTIDADES
const ENTITIES_DIRECTORY =
  window?.ApplicableDirectory?.apiIdentidades || process.env.REACT_APP_API_IDENTIDADES_DEFAULT_DIR

export const fetchAccount = async () => {
  const res = await communication.get(`${ACCOUNT_BASE_URL}/${ACCOUNT_DIRECTORY}`, '/cuenta')
  return res?.data
}
export const fetchAccountCommunities = async () => {
  const res = await communication.get(`${ACCOUNT_BASE_URL}/${ACCOUNT_DIRECTORY}`, '/comunidades')
  return res?.data
}



export const editAccount = async data => {
  return communication.put(`${ACCOUNT_BASE_URL}/${ACCOUNT_DIRECTORY}`, '/cuenta', JSON.stringify(data))
}

export const editAccountImage = async image => {
  return communication.put(
    `${ACCOUNT_BASE_URL}/${ACCOUNT_DIRECTORY}`,
    '/cuenta/imagen',
    JSON.stringify({ imagen: image }),
  )
}

export const checkSession = async () => {
  const res = await communication.get(`${ENTITIES_BASE_URL}/${ENTITIES_DIRECTORY}`, '/sesion')
  return res?.data
}

export const requestNewPassword = async user => {
  return communication.post(
    `${ENTITIES_BASE_URL}/${ENTITIES_DIRECTORY}`,
    '/credenciales-solicitud/externa',
    JSON.stringify({
      usuario: user,
      aplicacion: process.env.REACT_APP_ATLAS_ID,
      metodo: 'correo_electronico',
    }),
  )
}

import { useQuery } from 'react-query'
import { fetchFrequentQuestions } from 'services'
import { queryClient, fetchGetFrequentQuestionsKey } from 'utils'

export const useFrequentQuestionQuery = ({ hardLoad }) => {
  const {
    data: frequentQuestionData,
    isLoading: isFrequentQuestionLoading,
    isError: isFrequentQuestionError,
    isSuccess: isFrequentQuestionSuccess,
    refetch: refetchFrequentQuestion,
  } = useQuery(
    [fetchGetFrequentQuestionsKey],
    async () => {
      const stateQueryAtlas = queryClient.getQueryState(fetchGetFrequentQuestionsKey)
      if (stateQueryAtlas?.data && !hardLoad) {
        return stateQueryAtlas.data
      }
      const frequentQuestionData = await fetchFrequentQuestions()
      return frequentQuestionData?.informacion || []
    },
    { enabled: true },
  )

  return {
    frequentQuestionData,
    isFrequentQuestionLoading,
    isFrequentQuestionError,
    isFrequentQuestionSuccess,
    refetchFrequentQuestion,
  }
}

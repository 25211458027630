import palette from 'theme/base/palette'

const divider = {
  props: {
    MuiDivider: {
      light: true,
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        light: {
          backgroundColor: palette.neutral.light,
        },
      },
    },
  },
}

export default divider

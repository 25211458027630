import React, { useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'


import {
  AppBar,
  Toolbar,
  ListItemButton,
  Collapse,
  Typography,
  ListItem,
  List,
  Box,
  Drawer
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ListItemText from "@mui/material/ListItemText";
import useTranslation from "hooks/useTranslation";
import CssBaseline from "@mui/material/CssBaseline";
import { FileText } from "phosphor-react";
import { useMenuCollectionsQuery } from 'services/menuCollection/query.service'

import { ReactComponent as Down } from "assets/icons/down.svg";
import { ReactComponent as Right } from "assets/icons/right.svg";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  icon: {
    width: '13px',
    height: '13px',
    margin: 11,
  }
}));



export const SupportMenu = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(1);
  const [weight, setWeight] = useState(1);

  const t = useTranslation()
  const classes = useStyles();
  const navigate = useNavigate();

  const { isMenuCollectionsLoading } = useMenuCollectionsQuery({ hardLoad: false })


  const handleClick = (event, id) => {
    setSelectedId(id);
    setOpen(!open);
  };


  const handleChange = (event, idColeccion, idArticulo) => {
    setWeight(idArticulo);
    navigate(`/support-collection/${idColeccion}/support-articles/${idArticulo}`, {replace: true });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position={'fixed'}
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          zIndex: 1,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box"
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <List
          sx={{ width: "100%", marginTop: 3.87 }}
          component="nav"
          aria-labelledby="nested-list-subheader">
          <ListItem disablePadding key={-1}>
            <ListItemButton sx={{ marginBottom: 2 }}>
              <FileText size={24} />
              <ListItemText fontWeight='bold' sx={{ marginTop: 1, marginLeft: 0.5}} primary={<Typography variant='h2Mobile' >{t('side_menu:support_items')}</Typography>} disableTypography />
            </ListItemButton>
          </ListItem>
          {isMenuCollectionsLoading === false ? (
            <>
              {data?.map((coleccion, index) => {
                return (
                  <Fragment key={index}>
                    <ListItem disablePadding  >
                      <ListItemButton
                      disableRipple
                        onClick={event => handleClick(event, coleccion.id)} selected={selectedId === coleccion.id} >
                        {selectedId === coleccion.id ? <Down className={classes.icon} /> : <Right className={classes.icon} />}
                        <ListItemText selected={selectedId === coleccion.id} primary={<Typography variant={selectedId === coleccion.id ? 'h2Mobile' : 'body1'} >{coleccion?.nombre}</Typography>}
                          disableTypography />
                      </ListItemButton>
                    </ListItem>
                    {coleccion?.articulos.map((articulo, index) => {
                      return (
                        <Collapse in={selectedId === coleccion?.id} timeout="auto" unmountOnExit key={index} fontWeight={weight === coleccion.id ? 'bold' : 'none'}>
                          <List disablePadding>
                            <ListItemButton sx={{ pl: 5, pt:0, pr: 0}} onClick={event => {
                              handleChange(event, coleccion.id, articulo.id)
                            }}>
                              <ListItemText
                                disableTypography
                                primary={<Typography variant={'body2'} fontWeight={weight === articulo.id ? 'bold' : 'none'}>{articulo.titulo}</Typography>} />
                            </ListItemButton>
                          </List>
                        </Collapse>
                      );
                    })}
                  </Fragment>
                );
              })}
            </>
          )
            :
            null
          }
        </List >
      </Drawer >
    </Box >
  );
}
SupportMenu.propTypes = {
  data: PropTypes.array,
}

export default SupportMenu
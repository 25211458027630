import { Box, Link, Typography } from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import useStyles from './styles'

const APP_ATLAS_URL = process.env.REACT_APP_ATLAS_URL

export const NotFound = props => {
  const classes = useStyles()
  const t = useTranslation()

  return (
    <div className={classes.root}>
      <Box
        p={2}
        width="100vw"
        height="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box mb={2}>
          <Typography variant="h2" className={classes.title}>
            {t('errors:msg:view_error')} 404
          </Typography>
        </Box>
        <Typography variant="h6" align="center" className={classes.text}>
          {t('errors:msg:the_content_you_are_looking_for_is_not_found')}
          <br />
          {t('errors:msg:view_click')}&nbsp;
          <Link href={APP_ATLAS_URL} className={classes.link}>
            {t('errors:msg:view_here')}
          </Link>
          &nbsp;{t('errors:msg:view_to_back')}
        </Typography>
      </Box>
    </div>
  )
}

export default NotFound

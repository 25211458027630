import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Grid, Typography } from '@mui/material'
import { ErrorController, GradientCircularProgress } from 'ui'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
  },
})

export const AccessView = ({ text }) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root}>
      <Box mb={5}>
        <ErrorController type="icon" iconSize={32}>
          <Typography component="h4" variant="body1" align="center">
            {text}
          </Typography>
        </ErrorController>
      </Box>
      <Box display="flex" justifyContent="center">
        <GradientCircularProgress value={100} variant="indeterminate" />
      </Box>
    </Grid>
  )
}
 
AccessView.propTypes = {
  text: PropTypes.string,
}
export default AccessView

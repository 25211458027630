import { createTheme } from '@mui/material/styles'
import palette from 'theme/base/palette'
import { X } from 'phosphor-react'

import fonts from 'theme/base/fonts'
const defaultTheme = createTheme()

const chips = {
  props: {
    MuiChip: {
      deleteIcon: <X />,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          // height: 26,
          backgroundColor: palette.primary.light,
          color: palette.primary.dark,
          ...fonts.subtitle2,
        },
        icon: {
          color: 'inherit',
        },
        deleteIcon: {
          width: defaultTheme.spacing(2),
          height: defaultTheme.spacing(2),
          color: palette.neutral.dark,
        },
      },
    },
  },
}

export default chips

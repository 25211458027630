import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        background: 'linear-gradient(90.87deg, #4549D2 0.86%, #09DAA4 99.38%, #09DBA4 99.39%)'
    },
    title: {
        color: theme.palette.neutral.white,
        fontSize: '36px',
        textAlign: 'center'
    },
    text: {
        color: theme.palette.neutral.white,
        textAlign: 'center'
    },
    link: {
        color: theme.palette.neutral.white,
    }
}))

export default useStyles
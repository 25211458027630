import { useCallback, useContext, useState } from 'react'
import AuthContextProvider from 'context/auth/AuthContextProvider'
import ClientOAuth2 from 'ClientOAuth2'

export const useAuth = () => {
  const { token, setToken, accountData, setAccountData } = useContext(AuthContextProvider)
  const [isLoadingLogin, setLoadingLogin] = useState(false)
  const [isLoadingLogout, setLoadingLogout] = useState(false)

  const login = useCallback(
    sessionToken => {
      setLoadingLogin(true)
      ClientOAuth2.saveSessionToken(sessionToken)
      setToken(sessionToken)
      setLoadingLogin(false)
    },
    [setToken],
  )

  const logout = useCallback(() => {
    setLoadingLogout(true)
    localStorage.removeItem('sessionToken')
    setToken(null)

    setTimeout(() => {
      setLoadingLogout(false)
    }, 3000)
  }, [setToken])

  return {
    isLogged: Boolean(token),
    login,
    logout,
    isLoadingLogin,
    isLoadingLogout,
    accountData,
    setAccountData,
  }
}

export default useAuth

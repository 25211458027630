import PropTypes from 'prop-types'
import { SnackbarProvider } from 'notistack'
import { makeStyles } from '@mui/styles'

const MAX_SNACKBAR = 1
const AUTO_HIDE_DURATION = 7000 
const POSITION = {
  vertical: 'bottom',
  horizontal: 'left',
}

const useStyles = makeStyles(theme => ({
  variantCustom: {
    backgroundColor: `${theme.palette.neutral.black} !important`,
    color: 'white !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    height: '52px !important',
    padding: '0px 16px !important',
    borderRadius: '4px !important',
    '& .SnackbarItem-action': {
      marginRight: 0,
      paddingLeft: '8px',
    },
  },
}))

export const NotistackWrapper = ({ children }) => {
  const classes = useStyles()
  return (
    <SnackbarProvider
      preventDuplicate
      hideIconVariant
      maxSnack={MAX_SNACKBAR}
      autoHideDuration={AUTO_HIDE_DURATION}
      anchorOrigin={POSITION}
      classes={{
        variantSuccess: classes.variantCustom,
        variantError: classes.variantCustom,
      }}
    >
      {children}
    </SnackbarProvider>
  )
}
NotistackWrapper.propTypes = {
  children: PropTypes.node,
}
export default NotistackWrapper

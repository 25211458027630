import { createTheme } from '@mui/material/styles'
import fonts from 'theme/base/fonts'
import palette from 'theme/base/palette'

const defaultTheme = createTheme()

const button = {
  components: {
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "32px",
          padding: defaultTheme.spacing(1, 3),
          color: palette.primary.main,
          ...fonts.body1,
          fontWeight: 500,
          lineHeight: 1.25,
          textTransform: "none",
          "&:hover": {
            backgroundColor: palette.primary.lighter,
          },
          "&.Mui-disabled": {
            opacity: 0.4,
            backgroundColor: palette.primary.main,
          },
          "&.active": {
            backgroundColor: palette.primary.light,
          },
          "&.Mui-focusVisible": {
            backgroundColor: palette.primary.light,
          },
        },
        text: {
          padding: defaultTheme.spacing(1, 3),
        },
        contained: {
          color: defaultTheme.palette.common.white,
        },
        outlined: {
          padding: defaultTheme.spacing(1, 3),
          borderColor: palette.primary.light,
        },
        sizeSmall: {
          padding: defaultTheme.spacing(1, 2),
          ...fonts.body2,
          fontWeight: 500,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          "&:hover": {
            backgroundColor: palette.primary.lighter,
          },
          "&.active": {
            backgroundColor: palette.primary.light,
          },
        },
      },
    },
  },
};

export default button

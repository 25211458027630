import palette from 'theme/base/palette'

const grid = {
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          '&.combo': {
            '& .MuiFormControl-root': {
              padding: 0,
            },
            '& .MuiGrid-item': {
              '& .MuiFilledInput-root': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
              '& + .MuiGrid-item': {
                '& .MuiFilledInput-root': {
                  borderTopRightRadius: '9999px',
                  borderBottomRightRadius: '9999px',
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                  borderLeftColor: palette.neutral.light,
                },
              },
            },
          },
        },
      },
    },
  },
}

export default grid

import InterThin100 from 'fonts/Inter/Inter-Thin.ttf'
import InterExtraLight200 from 'fonts/Inter/Inter-ExtraLight.ttf'
import InterLight300 from 'fonts/Inter/Inter-Light.ttf'
import InterRegular400 from 'fonts/Inter/Inter-Regular.ttf'
import InterMedium500 from 'fonts/Inter/Inter-Medium.ttf'
import InterSemiBold600 from 'fonts/Inter/Inter-SemiBold.ttf'
import InterBold700 from 'fonts/Inter/Inter-Bold.ttf'
import InterExtraBold800 from 'fonts/Inter/Inter-ExtraBold.ttf'
import InterExtraBlack900 from 'fonts/Inter/Inter-Black.ttf'

export const cssBaseline = {
  MuiCssBaseline: {
    '@global': {
      html: {
        fontSize: 16,
      },
    },
    styleOverrides: `
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 100;
      src: url(${InterThin100}) format('truetype');
    }
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 200;
      src: url(${InterExtraLight200}) format('truetype');
    }
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 300;
      src: url(${InterLight300}) format('truetype');
    }
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      src: url(${InterRegular400}) format('truetype');
    }
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      src: url(${InterMedium500}) format('truetype');
    }
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      src: url(${InterSemiBold600}) format('truetype');
    }
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      src: url(${InterBold700}) format('truetype');
    }
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 800;
      src: url(${InterExtraBold800}) format('truetype');
    }
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 900;
      src: url(${InterExtraBlack900}) format('truetype');
    }
    `,
  },
}

export default cssBaseline

import React, { useContext } from 'react'
import palette from 'theme/base/palette'
import { Link, Typography } from '@mui/material'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import useTranslation from 'hooks/useTranslation'
import GlobalContextProvider from 'context/global/GlobalContextProvider'
import { useMediaQuery, useTheme } from '@mui/material'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  gridItem: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  linksGroup: {
    display: 'flex',
    alignItems: 'center',
    color: palette.neutral.white,
  },
  versionContainer: {
    position: 'absolute',
    right: '5%',
    bottom: '25px',
    width: '100px',
    '&.underSm': {
      bottom: '50px',
      right: 'calc(50% - 50px)',
      textAlign: 'center',
    },
  },
}))

export const Footer = () => {
  const classes = useStyles()
  const preventDefault = event => event.preventDefault()
  const t = useTranslation()
  const theme = useTheme()
  const location = useLocation()

  const { version } = useContext(GlobalContextProvider)

  const underSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="flex-end" sx={{ position: 'relative' }}>
        <Grid item minHeight={'43px'} lg={4}>
          <Grid
            container
            direction={'column'}
            display={'flex'}
            className={classes.gridItem}
            sx={{ color: palette.neutral.main }}
          >
            <Typography variant="subtitle1" className="point">
              {t('footer:galaxy')}
            </Typography>
            <Grid item display={'flex'} className={classes.gridItem} sx={{ color: palette.neutral.main }}>
              <Link
                color={palette.neutral.main}
                className="links"
                variant="subtitle1"
                href="https://www.cofm.es/es/"
                target="_blank"
              >
                {t('footer:COFM')}
              </Link>
              <Typography variant="subtitle1" className="point">
                &nbsp;•&nbsp;
              </Typography>
              <Link
                color={palette.neutral.main}
                className="links"
                variant="subtitle1"
                href="https://galaxy.cofm.es/"
                target="_blank"
                onClick={preventDefault}
              >
                {t('footer:access_galaxy')}
              </Link>
            </Grid>
          </Grid>
        </Grid>
        {location?.pathname === '/init' && (
          <div className={clsx(classes.versionContainer, underSm ? 'underSm' : '')}>
            <Typography variant="subtitle2" color={palette.neutral.main}>
              {version}
            </Typography>
          </div>
        )}
      </Grid>
    </>
  )
}

export default Footer

const typography = {
  props: {
    MuiTypography: {
      styleOverrides: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
        },
      },
    },
  },
}

export default typography

import { createTheme } from '@mui/material/styles'
import fonts from 'theme/base/fonts'
import palette from 'theme/base/palette'
import { CaretDown } from 'phosphor-react'

const defaultTheme = createTheme()

const table = {
  props: {
    MuiTablePagination: {
      selectIcon: <CaretDown />,
    },
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: palette.neutral.white,
        },
      },
    },
    MuiTableHeader: {
      styleOverrides: {
        root: {
          color: palette.neutral.dark,
          ...fonts.subtitle1,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root.index': {
            color: palette.neutral.main,
            width: '40px',
          },
          '&.MuiTableRow-hover': {
            '&:hover': {
              transition: defaultTheme.transitions.create(['background'], {
                easing: defaultTheme.transitions.easing.sharp,
                duration: defaultTheme.transitions.duration.shorter,
              }),
              backgroundColor: palette.primary.hover,
              cursor: 'pointer',
              '& .MuiTableCell-body': {
                color: palette.primary.dark,
              },
              '& .MuiTableCell-body.edit': {
                opacity: 1,
              },
            },
          },
          '& > tr, th, td': {
            height: '40px !important',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          maxWidth: defaultTheme.spacing(30),
          height: defaultTheme.spacing(5),
          padding: defaultTheme.spacing(0.5, 2),
          borderBottomColor: palette.neutral.light,
          ...fonts.subtitle1,
        },
        head: {
          color: palette.neutral.dark,
        },
        body: {
          color: palette.primary.dark,
        },
      },
    },
    MuiTableSortLabel: {
    },
    MuiTablePagination: {
    },
  },
}

export default table

import makeStyles from '@mui/styles/makeStyles'

const drawerWidth = 280
const menuWidth = 76

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: menuWidth,
    marginLeft: -drawerWidth,
    height: '100vh',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create(['margin-left', 'padding-left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  contentOpen: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      paddingLeft: 0,
    },
  },
  contentOpenFilters: {
    [theme.breakpoints.up('md')]: {
      marginLeft: menuWidth,
      paddingLeft: 0,
    },
  },
  mainContainer: {
    padding: theme.spacing(2),
    paddingTop: '24px !important',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 4, 6),
    },
  },
  codeContainer: {
    height: '61px',
    minHeight: '61px',
    maxHeight: '61px',
    paddingRight: '71px',
  },
}))

export default useStyles

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  pageMainContainer: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    background: '#fff',
    zIndex: 99999999999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  pageMainContainerTitle: {
    textAlign: 'center',
  },
  sectionMainContainer: {
    width: '100%',
    height: '100%',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    textAlign: 'center',
    '&.medium': { height: '200px !important' },
    '&.small': { height: '100px !important', marginBottom: '20px' },
  },
  sectionMainContainerCard: {
    width: '100%',
    height: '513px',
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    textAlign: 'center',
    '&.medium': { height: '400px !important' },
    '&.small': { height: '300px !important', marginBottom: '20px' },
  },
  sectionMainIcon: {
    '&.large': { width: '85px', height: '85px' },
    '&.medium': { width: '70px', height: '70px' },
    '&.small': { width: '50px', height: '50px' },
  },
  sectionMainContainerTitle: {
    textAlign: 'center',
    color: theme.palette.neutral.dark,
  },
  iconMainContainer: {
    maxWidth: '255px',
  },
  iconMainContainerTitle: {
    marginLeft: '12px',
    color: theme.palette.neutral.dark,
  },
  dialogHeader: {
    height: 90,
    minHeight: 90,
    borderBottom: `1px solid ${theme.palette.neutral.light}`,
    padding: '8px 16px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'move',
    },
  },
  dialogHeaderButtonClose: {
    position: 'absolute',
    right: '20px',
    top: '25px',
    display: 'flex',
    justifyContent: 'end',
  },
  dialogHeaderTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default useStyles

import { ContactForm } from 'ui'
import { useContactForm } from 'forms'
import { equals } from 'ramda'

export const Contact = () => {
  const {
    formTouched,
    formState,
    handleSubmit,
    onChangeValueState,
    defaultFormState,
    onChangeTouched,
    addNewContactUs,
  } = useContactForm()

  return (
    <>
      <ContactForm
        formState={formState}
        handleSubmit={handleSubmit}
        formTouched={formTouched}
        onChangeValueState={onChangeValueState}
        hasFormChange={!equals(formState, defaultFormState)}
        onChangeTouched={onChangeTouched}
        isLoading={addNewContactUs?.isLoading}
      />
    </>
  )
}

export default Contact

import axios from "axios";

export const HeaderInterceptor = () => {
  axios.interceptors.request.use(async function (req) {
    const isLoggedIn = localStorage.getItem("sessionToken");
    if (isLoggedIn) {
      req.headers["authorization"] = `Bearer ${isLoggedIn}`;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      axios.defaults.headers.put["Content-Type"] = "application/json";
      axios.defaults.headers.patch["Content-Type"] = "application/json";
    }

    return req;
  });
};
export default HeaderInterceptor;

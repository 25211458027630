import { Box, Grid, Typography, Tooltip } from '@mui/material'
import { ReactComponent as ErrorWarning } from 'assets/icons/error_warning.svg'
import useTranslation from 'hooks/useTranslation'
import useStyles from './styles'
import PropTypes from 'prop-types'

const ErrorControllerIcon = ({ iconSize = 28, showText = true, showTooltip = false, sx }) => {
  const classes = useStyles()
  const t = useTranslation()
  return (
    <>
      <Box sx={sx} className={classes.iconMainContainer}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-start" wrap="nowrap">
          <Grid item>
            <Tooltip title={showTooltip ? t('error_controller:icon:msg:refresh') : ''} placement="right-end">
              <ErrorWarning style={{ width: iconSize, height: iconSize }} />
            </Tooltip>
          </Grid>
          {showText && (
            <Grid item>
              <Typography variant="body1" className={classes.iconMainContainerTitle}>
                {t('error_controller:icon:msg:refresh')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

ErrorControllerIcon.propTypes = {
  iconSize: PropTypes.number,
  showText: PropTypes.bool,
  showTooltip: PropTypes.bool,
  sx: PropTypes.object,
}

export default ErrorControllerIcon

import makeStyles from '@mui/styles/makeStyles'

export const globalUseStyles = makeStyles(theme => ({
  disabled: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
}))

export default globalUseStyles

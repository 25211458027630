import erroresList from 'config/errors/key-errors.js'

export const messageErrors = error => {
  const defaultError = erroresList['error.default']?.messageKey

  //Errores de estado
  if ([400, 401, 403, 404, 500].includes(error?.request?.status) && !error?.response?.data) {
    return { error: error?.request?.status, messageKey: erroresList[`error.${error?.request?.status}`]?.messageKey }
  }

  // Errores API
  const familiaID = error?.response?.data?.errores[0]?.familiaID
    ? error?.response?.data?.errores[0]?.familiaID
    : error?.response?.data?.familiaID

  if (!erroresList[familiaID]) {
    return { error: 'Error no especificado', messageKey: defaultError }
  } else {
    const messageKeyError = erroresList[familiaID]?.messageKey ? erroresList[familiaID]?.messageKey : null
    return { error: familiaID, messageKey: messageKeyError ? messageKeyError : defaultError }
  }
}

export default messageErrors

import randomstring from 'random-string'

const stringToQuery = params =>
  Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&')

const generateCodeChallenge = async codeVerifier => {
  const encoder = new TextEncoder()
  const data = encoder.encode(codeVerifier)
  const digest = await window.crypto.subtle.digest('SHA-256', data)
  const base64Digest = btoa(String.fromCharCode(...new Uint8Array(digest)))
  return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

class AuthorizationParams {
  constructor(client_id, response_type, scope, authAction) {
    this.client_id = client_id
    this.response_type = response_type
    this.scope = scope
    this.state = authAction ? `${randomstring({ length: 8 })}-${authAction}` : randomstring({ length: 8 })

    this.code_challenge_method = 'S256'
    this.code_challenge = null
  }
}

class TokenParams {
  constructor(clientId, codeVerifier, redirectUri) {
    this.client_id = clientId
    this.code_verifier = codeVerifier
    this.redirect_uri = redirectUri
    this.grant_type = 'authorization_code'
  }
}

class ClientOAuth2 {
  constructor(
    { client_id, response_type, scope, authorizationUri, redirectUri },
    authAction,
  ) {
    this.authorizationParams = new AuthorizationParams(
      client_id,
      response_type,
      scope,
      authAction,
    )
    this.redirectUri = redirectUri
    this.authorizationUri = authorizationUri
  }

  saveTokenParams(tokenParams) {
    localStorage.setItem('tokenParams', JSON.stringify(tokenParams))
  }

  getTokenParams() {
    return JSON.parse(localStorage.getItem('tokenParams'))
  }

  static saveSessionToken(token) {
    localStorage.setItem('sessionToken', token)
    sessionStorage.setItem('sessionToken', token)
  }

  async getAuthorization() {
    const codeVerifier = randomstring({ length: 128 });
    this.authorizationParams.code_challenge = await generateCodeChallenge(
      codeVerifier,
    )
    const uri = `${this.authorizationUri}?${stringToQuery(
      this.authorizationParams,
    )}`
    const tokenParams = new TokenParams(
      this.authorizationParams.client_id,
      codeVerifier,
      this.redirectUri,
    )
    this.saveTokenParams(tokenParams)
    return uri
  }
}

export default ClientOAuth2

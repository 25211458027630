import { TechnicalAssistanceFormView } from 'ui'
import { Error } from 'forms'

export const ErrorFormView = () => {
  return (
    <TechnicalAssistanceFormView title={'error_form:input:report_error'} subtitle={'error_form:input:description'}>
      <Error></Error>
    </TechnicalAssistanceFormView>
  )
}

export default ErrorFormView

import { Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'hooks'
import { Cards, ErrorController } from 'ui'
import { BugBeetle, Chat, Lightbulb } from 'phosphor-react'

export const AssitsView = () => {
  let cardsContent = [
    {
      nav: '/contact-form',
      title: 'variant_card:title:contact_us',
      description: 'form:input:description',
      icon: <Chat />,
    },
    {
      nav: '/function-form',
      title: 'variant_card:title:request_a_feature',
      description: 'form:input:description',
      icon: <Lightbulb />,
    },
    {
      nav: '/error-form',
      title: 'variant_card:title:reppot_error',
      description: 'form:input:description',
      icon: <BugBeetle />,
    },
  ]

  const t = useTranslation()
  const theme = useTheme()
  const smallUp = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box width={'100%'}>
      <Grid container columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }} justifyContent="flex-start">
        <Grid container justifyContent="center" alignContent="center" mb={3} mt={{ xxs: '0', xs: '0', md: '0' }}>
          <ErrorController type="icon" iconSize={32}>
            <Typography variant={smallUp ? 'h3' : 'h1Mobile'} align="center">
              {t('assist_view:title:what_are_you_do')}
            </Typography>
          </ErrorController>
        </Grid>
        <Grid
          container
          justifyContent="center"
          columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }}
          pr={{ xss: '16px', xs: '16px', sm: '40px', md: '80px', lg: '88px', xl: '88px' }}
          pl={{ xss: '16px', xs: '16px', sm: '40px', md: '80px', lg: '88px', xl: '88px' }}
        >
          <ErrorController type="section" size="medium" isCard={true}>
            {cardsContent.map(({ nav, title, description, icon }, index) => (
              <Grid
                item
                key={index}
                xs={4}
                sm={8}
                md={6}
                lg={4}
                xl={4}
                p={{
                  xss: '0px 0px 18px 0px',
                  xs: '0px 0px 18px 0px',
                  sm: '0px 0px 18px 0px',
                  md: '0px 16px 18px 0px',
                  lg: '0px 16px 18px 0px',
                  xl: '0px 16px 18px 0px',
                }}
                width={'100%'}
                sx={{
                  maxWidth: { xss: '640px', xs: '640px', sm: '640px', md: '432px', lg: '427px', xl: '440px' },
                }}
              >
                <Cards
                  nav={nav}
                  title={title}
                  description={description}
                  icon={icon}
                  sx={{
                    width: { xss: '100%', xs: '100%', sm: '640px', md: '416px', lg: '411px', xl: '424px' },
                    maxWidth: { xss: '100%', xs: '100%', sm: '640px', md: '416px', lg: '411px', xl: '424px' },
                    height: { xss: '149px', xs: '149px', sm: '206px', md: '206px', lg: '206px', xl: '206px' },
                  }}
                />
              </Grid>
            ))}
          </ErrorController>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AssitsView

import axios from 'axios'
const communication = {
  get: _get,
  post: _post,
  put: _put,
  delete: _delete,
}

/**
 * Metodo get
 * @param {string} baseUrl
 * @param {string} url
 * @param {object} params
 * @returns
 */
function _get(baseUrl, url, params) {
  const completeUrl = `${baseUrl}${url}`
  const request = axios({
    method: 'GET',
    url: completeUrl,
    data: params,
  })
  return request
}

/**
 * Metodo post
 * @param {string} baseUrl
 * @param {string} url
 * @param {object} params
 * @returns
 */
function _post(baseUrl, url, params) {
  const completeUrl = `${baseUrl}${url}`
  const request = axios({
    method: 'POST',
    url: completeUrl,
    data: params,
  })

  return request
}
/**
 * Metodo post
 * @param {string} baseUrl
 * @param {string} url
 * @param {object} params
 * @returns
 */

function _put(baseUrl, url, params) {
  const completeUrl = `${baseUrl}${url}`

  const request = axios({
    method: 'PUT',
    url: completeUrl,
    data: params,
  })
  return request
}

/**
 * Metodo delete
 * @param {string} baseUrl
 * @param {string} url
 * @param {string} params
 */
function _delete(baseUrl, url, params) {
  const completeUrl = `${baseUrl}${url}`

  const request = axios({
    method: 'DELETE',
    url: completeUrl,
    data: params,
  })

  return request
}



export default communication

import { useCallback, Fragment } from 'react'
import { useSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton'
import { X } from 'phosphor-react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  closeButton: {
    color: theme.palette.neutral.white,
    '&:hover, &.Mui-focusVisible': {
      color: theme.palette.primary.main,
    },
  },
}))

export const useMessageSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const classes = useStyles()
  const showSnackbar = useCallback(
    ({ type, message, delay = 0 }) => {
      const closeAction = key => (
        <Fragment>
          <IconButton className={classes.closeButton} onClick={() => closeSnackbar(key)}>
            <X size="20" weight="bold" />
          </IconButton>
        </Fragment>
      )
      const customization = {
        success: {
          variant: type,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          action: closeAction,
        },
        error: {
          variant: type,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          action: closeAction,
        },
        default: {
          variant: type,
        },
      }
      const optionsObject = customization[type] ? customization[type] : customization['default']
      setTimeout(() => {
        enqueueSnackbar(message, optionsObject)
      }, delay)
    },
    [classes, enqueueSnackbar, closeSnackbar],
  )

  return showSnackbar
}
export default useMessageSnackbar

import fonts from '../base/fonts'
import palette from './../base/palette'
import { CaretDown } from 'phosphor-react'
import { alpha } from '@mui/material/styles'

const rootStyles = {
  border: `1px solid ${palette.primary.light}`,
  minHeight: '40px',
  borderRadius: '32px',
  backgroundColor: palette.neutral.white,
  '&:hover, &:active': {
    backgroundColor: `${palette.neutral.white} !important`,
    border: `1px solid ${palette.primary.main}`,
  },
  '&:focus, &.Mui-focused': {
    color: palette.primary.main,
    backgroundColor: `${palette.primary.lighter} !important`,
  },
  '&.Mui-focused': {
    border: `1px solid ${palette.primary.main} !important`,
  },
  '&.Mui-focused:before': {
    borderBottom: '0px !important',
    content: "''",
  },
  '&.Mui-focused:after': {
    borderBottom: '0px !important',
    content: "''",
  },
  '&:before': {
    borderBottom: '0px !important',
    content: "''",
  },
  '&:after': {
    borderBottom: '0px !important',
    content: "''",
  },
  '&.Mui-error': {
    borderColor: palette.error.light,
    backgroundColor: `${palette.error.lighter} !important`,
    color: palette.error.main,
  },
  '&.Mui-disabled': {
    backgroundColor: `${palette.neutral.lighter} !important`,
    border: `1px solid ${palette.neutral.main_light} !important`,
    '& input': {
      WebkitTextFillColor: `${palette.primary.dark} !important`,
    },
    '& button.MuiAutocomplete-popupIndicator, & button.MuiAutocomplete-clearIndicator': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
}

const inputStyles = {
  minHeight: '24px',
  padding: '0px 16px',
  caretColor: palette.primary.main,
  boxSizing: 'border-box',
  '&::placeholder': {
    opacity: 1,
    color: palette.neutral.main,
    fontSize: '16px',
  },
  '&:-webkit-autofill': {
    borderRadius: '9999px',
    borderTopLeftRadius: '9999px',
    borderTopRightRadius: '9999px',
    WebkitBoxShadow: `0 0 0 100px ${palette.neutral.lighter} inset`,
    borderColor: palette.primary.light,
  },
  '&:read-only:not(.MuiSelect-root)': {
    border: 'transparent',
    pointerEvents: 'none',
  },
  '&.Mui-disabled': {
    pointerEvents: 'none',
    color: palette.primary.dark,
    webkitTextFillColor: `${palette.primary.dark} !important`,
  },
}

const form = {
  props: {
    MuiTextField: {
      fullWidth: true,
    },
    MuiFormControl: {
      fullWidth: true,
    },
    MuiInput: {
      fullWidth: true,
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiFilledInput: {
      disableUnderline: true,
    },
  },

  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiFormControlLabel-label': {
            fontSize: '14px',
            color: '#626281',
          },
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'filled' },
        },
      ],
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: palette.primary.dark,
            webkitTextFillColor: `${palette.primary.dark} !important`,
          },
          '&.hasValue': {
            '& .MuiFilledInput-root:not(.Mui-error)': {
              backgroundColor: palette.primary.lighter,
            },
            '& .MuiInput-root:not(.Mui-error)': {
              backgroundColor: palette.primary.lighter,
            },
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          ...rootStyles,
          '&.Mui-disabled ': {
            backgroundColor: `${palette.neutral.lighter} !important`,
            borderColor: palette.neutral.main,
          },
          '&.Mui-disabled:hover': {
            backgroundColor: `${palette.neutral.lighter} !important`,
          },
        },
        input: {
          ...inputStyles,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <CaretDown />,
      },
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            paddingTop: '0 !important',
          },
          '& button.MuiAutocomplete-popupIndicator, & button.MuiAutocomplete-clearIndicator': {
            color: palette.neutral.dark,
          },
          '& button.MuiAutocomplete-popupIndicator:hover, & button.MuiAutocomplete-clearIndicator:hover': {
            color: palette.primary.main,
          },
          '&.hasValue .MuiFilledInput-root': {
            backgroundColor: palette.primary.lighter,
          },
        },
        input: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        endAdornment: {
          top: 'calc(50% - 15px)',
          right: '20px !important',
          '& button:hover': {
            backgroundColor: 'transparent !important',
          },
        },
        option: {
          height: '35px !important',
          ...fonts.body1,
          color: palette.neutral.dark,
          '&:hover, &.Mui-focused': {
            backgroundColor: `${alpha(palette.primary.light, 0.2)} !important`,
            color: `${palette.primary.main} !important`,
          },
          '&[aria-selected="true"]': {
            backgroundColor: 'transparent !important',
            color: `${palette.primary.main} !important`,
          },
          '&[aria-selected="true"]:hover': {
            backgroundColor: `${alpha(palette.primary.light, 0.2)} !important`,
            color: `${palette.primary.main} !important`,
          },
        },
        tag: {
          height: '25px',
          marginTop: '5px !important',
          marginBottom: '5px !important',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...fonts.label,
          color: palette.neutral.dark,
          transform: 'none',
          position: 'relative !important',
          marginLeft: '16px',
          marginBottom: '12px',
          '& .MuiFormLabel-asterisk': {
            color: palette.error.dark,
          },
          '&$focused:not(:read-only)': {
            color: palette.primary.main,
          },
        },
        filled: {
          '&.MuiInputLabel-shrink': {
            transform: 'none',
          },
        },
        shrink: {
          transform: 'none',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          ...rootStyles,
          '&.MuiSelect-root': {
            minHeight: '40px !important',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...rootStyles,
          minHeight: '40px !important',
          backgroundColor: 'none',
          marginTop: '0px !important',
          '& .MuiInputAdornment-positionEnd > button:hover': {
            backgroundColor: 'transparent',
          },
        },
        input: {
          '& :not(.MuiTablePagination-input)': {
            ...inputStyles,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          minHeight: '15px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ...fonts.subtitle1,
          marginLeft: '14px !important',
          marginRight: '14px !important',
        },
      },
    },
    MuiFormControl: {
      variants: [
        {
          props: { variant: 'filled' },
        },
      ],
      styleOverrides: {
        root: {
          width: '100%',
          marginBottom: '16px',
          minHeight: '100px',

          '&.compact': {
            minHeight: '76px !important',
            '& label.MuiInputLabel-root': {
              marginBottom: '3px',
            },
            '& p.MuiFormHelperText-root': {
              marginTop: '2px !important',
            },
          },
          '&.compactNoHelpText': {
            minHeight: '8px',
            '& label.MuiInputLabel-root': {
              marginBottom: '1px !important',
            },
            '& p.MuiFormHelperText-root': {
              marginTop: '2px !important',
            },
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: CaretDown,
      },
      styleOverrides: {
        select: {
          paddingLeft: '16px',
          paddingRight: '40px !important',
          '&:focus': {
            backgroundColor: 'transparent !important',
          },
        },
        icon: {
          width: '24px',
          height: '24px',
          top: 'auto !important',
          marginRight: '10px',
          color: palette.neutral.dark,
          '&:hover': {
            color: palette.primary.main,
          },
        },
      },
    },
  },
}

export default form

import makeStyles from "@mui/styles/makeStyles";
import palette from "theme/base/palette";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
  input: {

    "& .MuiFilledInput-root": {
      borderRadius: "32px",
      height: "40px",
      border: "1px solid #E3E3E8",
      display: "flex",
      background: theme.palette.neutral.white,
      paddingRight: "0 !important",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: palette.primary.lighter,
    },
    "& .MuiFilledInput-root:focus": {
      backgroundColor: palette.primary.lighter,
    },
    "& .MuiInputBase-input": {
      border: "none !important",
      color: palette.neutral.dark,
    },
    "& .MuiIconButton-root": {
      color: theme.palette.neutral.dark,
      padding: "5px",
      right: "16px",
      margin: "3px",
    },
    "& .MuiIconButton-root:hover": {
      color: palette.primary.main,
    },
    "& .MuiIconButton-root:focus": {},
  },
}));

export default useStyles;

import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import cssBaseline from './base/cssBaseline'
import palette from './base/palette'
import fonts from './base/fonts'
import border from './base/border'
import shadows from './base/shadows'
import breakpoints from './base/breakpoints'
import elements from './elements'

const primitives = {
  spacing: 8,
  typography: fonts,
  palette,
  border,
  shadows,
  breakpoints: breakpoints,
}

const theme = createTheme(deepmerge(primitives, { components: { ...cssBaseline, ...elements.components } }))

export default theme

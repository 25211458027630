import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Typography, useTheme, Grid } from '@mui/material'
import useTranslation from 'hooks/useTranslation'
import ErrorController from 'ui/modules/errorController/errorController'

export const TechnicalAssistanceFormView = ({ title, subtitle, current, iconbreadcrumb, navigation, children }) => {
  const t = useTranslation()
  const theme = useTheme()

  return (
    <Grid
      container
      justifyContent="center"
      columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }}
      mt={{ xss: '24px', xs: '24px', sm: '24px', md: '24px', lg: '24px', xl: '24px' }}
    >
      <Grid
        item
        marginLeft={{ xss: '16px', xs: '16px', sm: '40px', md: '0px', lg: '0px', xl: '0px' }}
        marginRight={{ xss: '16px', xs: '16px', sm: '40px', md: '0px', lg: '0px', xl: '0px' }}
        xs={4}
        sm={8}
        md={10}
        lg={8}
        xl={8}
      >
        <Card>
          <CardContent
            sx={{
              padding: {
                xss: '16px 24px 16px 24px !important',
                xs: '16px 24px 16px 24px !important',
                sm: '16px 24px 16px 24px !important',
                md: '32px 56px 32px 56px !important',
                lg: '32px 56px 32px 56px !important',
                xl: '32px 56px 32px 56px !important',
              },
            }}
          >
            <Grid container justifyContent="center">
              <Grid item container justifyContent="center">
                <ErrorController type="icon" iconSize={32}>
                  <Typography variant="h4" textAlign="center" marginBottom="8px">
                    {t(title)}
                  </Typography>
                </ErrorController>
              </Grid>
              <Grid item container justifyContent="center">
                <ErrorController type="icon" iconSize={32}>
                  <Typography
                    sx={{
                      typography: {
                        xss: 'subtitle1',
                        xs: 'subtitle1',
                        sm: 'bodyMobile',
                        md: 'bodyMobile',
                        lg: 'bodyMobile',
                        xl: 'bodyMobile',
                      },
                      color: theme.palette.neutral.dark,
                    }}
                    component="p"
                    textAlign="center"
                    marginBottom={'16px'}
                  >
                    {t(subtitle)}
                  </Typography>
                </ErrorController>
              </Grid>
              <Grid item container justifyContent="center">
                {children}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

TechnicalAssistanceFormView.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  current: PropTypes.string,
  iconbreadcrumb: PropTypes.element,
  navigation: PropTypes.array,
}

export default TechnicalAssistanceFormView

import { useState } from 'react'
import PropTypes from 'prop-types'
import { TextareaAutosize, FormControl, InputLabel } from '@mui/material'
import clsx from 'clsx'
import useStyles from './styles'

export const TextAreaAutosize = ({ label, value, onChange, sx, onBlur, type, placeholder, minRows }) => {
  const classes = useStyles()
  const [isFocused, setFocus] = useState(false)

  const onFocus = () => {
    setFocus(true)
  }

  const handleOnBlur = () => {
    setFocus(false)
    onBlur()
  }

  return (
    <FormControl sx={sx} className={classes.formControl}>
      <InputLabel focused={isFocused} className={classes.label}>
        {label}
      </InputLabel>
      <TextareaAutosize
        placeholder={placeholder}
        minRows={minRows}
        className={clsx(classes.root, value ? 'hasValue' : '')}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={handleOnBlur}
      />
    </FormControl>
  )
}

TextAreaAutosize.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  sx: PropTypes.object,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  minRows: PropTypes.number,
}

export default TextAreaAutosize

import { ErrorView } from 'ui'

export const Error = props => {

  return (
    <ErrorView />
  )
}

export default Error

import { useQuery } from 'react-query'
import { queryClient, fetchServicesKey } from 'utils'

export const useServicesQuery = () => {
  const {
    data: services,
    isLoading: isServicesLoading,
    isError: isServicesError,
    isSuccess: isServicesSuccess,
    refetch: refetchServices,
  } = useQuery(
    [fetchServicesKey],
    async () => {
      const stateQueryServices = queryClient.getQueryState(fetchServicesKey)
      if (stateQueryServices?.data) {
        return stateQueryServices.data
      }
      return services ? services.informacion : []
    },
    { initialData: null, enabled: false },
  )

  return {
    services,
    isServicesLoading,
    isServicesError,
    isServicesSuccess,
    refetchServices,
  }
}

export const palette = {
  primary: {
    dark: '#0D1745',
    main_text: '#0005AF',
    main: '#4549D2',
    main500: '#5459FF', 
    light: '#E2E1FF',
    lighter: '#F9F9FF',
    hover: '#F0F0FF',
  },
  secondary: {
    dark: '#09DBA4',
    main: '#3BF29D',
    light: '#88FFC0',
    lighter: '#E7FFF2',
  },
  neutral: {
    black: '#000000',
    white: '#ffffff',
    dark: '#626281',
    main: '#B8B8C3',
    disabled: '#DADADF',
    light: '#E3E3E8',
    lighter: '#F8F8F9',
    main_light: '#E3E3E8',
  },
  success: {
    dark: '#00775A',
    main: '#09DBA4',
    light: '#88FFC0',
    lighter: '#F7FFFA',
  },
  warning: {
    dark: '#815D22',
    main: '#FFE177',
    light: '#FFFCF1',
    grey: '#646466',
    lighter: '#D8D8D8',
  },
  error: {
    dark: '#A40A00',
    main: '#CA4949',
    light: '#FFB7B7',
    lighter: '#FFF1F1',
  },
  text: {
    primary: '#0D1745',
  },
  hyperlink: {
    hover: '#67FFD7',
  },
  background: {
    lighter: '#ECECEC',
  },
}

export default palette

import { Route, Routes, Navigate } from 'react-router-dom'
import {
  Error,
  NotFound,
  InitView,
  FAQView,
  SupportView,
  ContactFormView,
  AssitsView,
  SupportArticlesList,
  SupportArticleView,
  ErrorFormView,
  FunctionFormView,
} from 'ui'

export const AppRouter = () => {
  return (
    <Routes basename="/init">
      <Route exact path="/error" element={<Error />} />
      <Route exact path="/faq" element={<FAQView />} />
      <Route exact path="/not-found" element={<NotFound />} />
      <Route exact path="/init" element={<InitView />} />
      <Route exact path="/support" element={<SupportView />} />
      <Route exact path="/assist" element={<AssitsView />} />
      <Route path="*" element={<Navigate to="/init" />} />
      <Route exact path="/contact-form" element={<ContactFormView />} />
      <Route exact path="/error-form" element={<ErrorFormView />} />
      <Route exact path="/function-form" element={<FunctionFormView />} />
      <Route exact path="/support-collection/:idColeccion" element={<SupportArticlesList />} />
      <Route
        extact
        path="/support-collection/:idColeccion/support-articles/:idArticulo"
        element={<SupportArticleView />}
      />
    </Routes>
  )
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
const AuthContext = React.createContext({})

export function AuthContextProvider({ children }) {
  const [token, setToken] = useState(() => localStorage.getItem('sessionToken'))
  const [accountData, setAccountData] = useState({})

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        accountData,
        setAccountData,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
export default AuthContext

import palette from 'theme/base/palette'

const linearProgress = {
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: palette.primary.main
        },
        bar: {
          backgroundColor: palette.primary.light,
        },
      },
    },
  },
}

export default linearProgress

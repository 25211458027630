import communication from 'utils/communication'

const REACT_APP_ATLAS = process.env.REACT_APP_ATLAS_CONECTION
const ATLAS_DIRECTORY =
  window?.ApplicableDirectory?.apiCliklup || process.env.REACT_APP_API_ATLAS_V2_DIR

export const fetchMenuCollections = async () => {
  const res = await communication.get(
    `${REACT_APP_ATLAS}/${ATLAS_DIRECTORY}`, `/articulos-apoyo/colecciones?estilo=M001`
  )
  return res?.data
}

export const fetchMenuCollection = async id => {
  const res = await communication.get(
    `${REACT_APP_ATLAS}/${ATLAS_DIRECTORY}`, `/articulos-apoyo/colecciones/${id}?estilo=M001`
  );
  return res?.data;

};



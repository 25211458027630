import { createTheme } from '@mui/material/styles'

const defaultTheme = createTheme()

const paper = {
  props: {
    elevation: 0,
    square: true,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.box': {
            overflow: 'hidden',
            padding: defaultTheme.spacing(5),
            '& + &': {
              marginTop: defaultTheme.spacing(3),
            },
          },
          '&.overflowHidden': {
            overflow: 'hidden',
          },
          '&.fullHeight': {
            height: '100%',
          },
          '&.marginTop': {
            marginTop: defaultTheme.spacing(2),
          },
        },
        rounded: {
          borderRadius: 12,
        },
      },
    },
  },
}

export default paper

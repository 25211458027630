import { Box, IconButton, Tooltip } from '@mui/material'
import { DotsThreeVertical } from 'phosphor-react'

export default {
  title: 'Atoms/Tooltips',
  component: Tooltip,
}

const Template = args => (
  <Box display="flex" justifyContent="center" p={2}>
    <Tooltip {...args} title="Delete" placement="top">
      <IconButton aria-label="My tooltip" size="large">
        <DotsThreeVertical />
      </IconButton>
    </Tooltip>
    <Tooltip
      {...args}
      title="Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus."
      placement="top"
    >
      <IconButton aria-label="delete" size="large">
        <DotsThreeVertical />
      </IconButton>
    </Tooltip>
  </Box>
)
export const Tooltips = Template.bind({})

Tooltips.args = {
  arrow: true,
}

import communication from 'utils/communication'

const REACT_APP_ATLAS = process.env.REACT_APP_ATLAS_CONECTION
const ATLAS_DIRECTORY =
  window?.ApplicableDirectory?.apiCliklup || process.env.REACT_APP_API_ATLAS_V2_DIR

export const fetchCollections = async () => {
  const res = await communication.get(
    `${REACT_APP_ATLAS}/${ATLAS_DIRECTORY}`, `/articulos-apoyo/colecciones?estilo=S001`
  )
  return res?.data
}

export const fetchArticles = async (idColeccion) => {
  const res = await communication.get(
    `${REACT_APP_ATLAS}/${ATLAS_DIRECTORY}`, `/articulos-apoyo/colecciones/${idColeccion}?estilo=M001`
  )
  return res?.data
}

export const fetchArticle = async (idColeccion, idArticulo) => {
  const res = await communication.get(
    `${REACT_APP_ATLAS}/${ATLAS_DIRECTORY}`, `/articulos-apoyo/colecciones/${idColeccion}/articulos/${idArticulo}?estilo=L001`
  )
  return res?.data
}


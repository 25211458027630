import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'linear-gradient(90.87deg, #4549D2 0.86%, #09DAA4 99.38%, #09DBA4 99.39%)',
  },
  title: {
    color: theme.palette.primary.dark,
    textAlign: 'center',
  },
  text: {
    color: theme.palette.primary.dark,
    textAlign: 'center',
  },
  codeError: {
    color: theme.palette.neutral.main,
    textAlign: 'center',
    fontWeight: '600',
  },
  link: {
    color: theme.palette.primary.main,
  },
}))

export default useStyles

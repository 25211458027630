import { useQuery } from 'react-query'
import { fetchAccount, fetchAccountCommunities, checkSession } from './communication.service'
import { useAuth } from 'hooks'
import { getInitials, queryClient, fetchAccountKey, fetchSessionKey } from 'utils'

export const useAccountQuery = ({ hardLoad }) => {
  const { setAccountData } = useAuth()

  const fetchAccountData = async () => {
    try {
      const stateQueryAccount = queryClient.getQueryState(fetchAccountKey)
      if (stateQueryAccount?.data && !hardLoad) {
        return stateQueryAccount.data
      }
      let account = await fetchAccount()
      if (account) {
        account.nombre = account.nombre || ''
        account.apellidos = account.apellidos || ''
        account.telefono = account.telefono || ''

        const alias = getInitials({ name: account.nombre, surname: account.apellidos })
        const comunidades = {}
        const communities = await fetchAccountCommunities()
        if (communities) communities.informacion.map(community => (comunidades[community.codigo] = community.nombre))
        setAccountData({ ...account, alias, comunidades })

        return { ...account, alias, comunidades }
      }
    } catch (error) {
      return null
    }
  }
  const {
    data: accountData,
    refetch: refetchAccountData,
    isLoading,
    isError,
  } = useQuery([fetchAccountKey], fetchAccountData, { enabled: false })

  return { accountData, refetchAccountData, isLoading, isError }
}
export const useSessionQuery = () => {
  const {
    data: session,
    isLoading: isSessionLoading,
    isError: isSessionError,
    isSuccess: isSessionSuccess,
    refetch: refetchSession,
  } = useQuery(
    [fetchSessionKey],
    async () => {
      const stateQuerysession = queryClient.getQueryState(fetchSessionKey)
      if (stateQuerysession?.data && Date.now() - stateQuerysession.dataUpdatedAt <= 900 * 1000) {
        return stateQuerysession.data
      }
      let session = await checkSession()
      session = session?.informacion?.estado ? session?.informacion?.estado : false
      return session
    },
    { initialData: null, enabled: false },
  )

  return {
    session,
    isSessionLoading,
    isSessionError,
    isSessionSuccess,
    refetchSession,
  }
}

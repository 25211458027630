import { Chat, BugBeetle, Lightbulb } from 'phosphor-react'

export const ROUTES = [
  {
    path: '/error',
    pattern: null,
    showBreadCrumb: false,
  },
  {
    path: '/faq',
    pattern: null,
    showBreadCrumb: true,
    navigation: [{ id: 1, label: 'Preguntas frecuentes', icon: null, link: null }],
  },
  {
    path: '/not-found',
    pattern: null,
    showBreadCrumb: false,
  },
  {
    path: '/init',
    pattern: null,
    showBreadCrumb: false,
  },
  {
    path: '/support',
    pattern: null,
    showBreadCrumb: true,
    navigation: [{ id: 1, label: 'Artículos de apoyo', icon: null, link: null }],
  },
  {
    path: '/assist',
    pattern: null,
    showBreadCrumb: true,
    navigation: [{ id: 1, label: 'Asistencia técnica', icon: null, link: null }],
  },
  {
    path: '/contact-form',
    pattern: null,
    showBreadCrumb: true,
    navigation: [
      { id: 1, label: 'Asistencia técnica', icon: null, link: '/assist' },
      { id: 2, label: 'Contacta con nosotros', icon: <Chat size={20} />, link: null },
    ],
  },
  {
    path: '/error-form',
    pattern: null,
    showBreadCrumb: true,
    navigation: [
      { id: 1, label: 'Asistencia técnica', icon: null, link: '/assist' },
      { id: 2, label: 'Reporta un error', icon: <BugBeetle size={20} />, link: null },
    ],
  },
  {
    path: '/function-form',
    pattern: null,
    showBreadCrumb: true,
    navigation: [
      { id: 1, label: 'Asistencia técnica', icon: null, link: '/assist' },
      { id: 2, label: 'Solicita función', icon: <Lightbulb size={20} />, link: null },
    ],
  },
  {
    path: '/support-articles/:uuid',
    pattern: null,
    showBreadCrumb: true,
  },
  {
    path: '/support-collection/:uuid',
    pattern: null,
    showBreadCrumb: true,
  },
]

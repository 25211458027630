const HYDRA_URL = process.env.REACT_APP_HYDRA

export const tokenize = async ({
    code,
    grant_type,
    code_verifier,
    redirect_uri,
    client_id,
}) => {
    const formData = new FormData()
    formData.append('grant_type', grant_type)
    formData.append('code', code)
    formData.append('code_verifier', code_verifier)
    formData.append('redirect_uri', redirect_uri)
    formData.append('client_id', client_id)
    const response = await fetch(`${HYDRA_URL}/token`, {
        method: 'POST',
        body: formData,
    })
    if (response.ok) {
        return response.json()
    }
}

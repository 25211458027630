import React, { useContext, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { AppBar, Toolbar, IconButton, Hidden, useMediaQuery, useTheme } from '@mui/material'
import GlobalContextProvider from 'context/global/GlobalContextProvider'
import clsx from 'clsx'
import { TextAlignJustify } from 'phosphor-react'
import logoImg from 'assets/images/atlas_svg.svg'
import useStyles from './styles'

export const Header = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { isMainMenuOpen, setIsMainMenuOpen } = useContext(GlobalContextProvider)

  const aboveMd = useMediaQuery(theme.breakpoints.up('md'))
  const underMd = useMediaQuery(theme.breakpoints.down('md'))
  const underSm = useMediaQuery(theme.breakpoints.down('sm'))

  const handleOpenMenu = () => {
    setIsMainMenuOpen(!isMainMenuOpen)
  }

  useEffect(() => {
    if (aboveMd === true) {
      setIsMainMenuOpen(false)
    }
  }, [aboveMd])

  return (
    <>
      <AppBar className={classes.root} position="fixed">
        <Toolbar
          className={clsx(
            classes.toolbar,
            underSm && classes.underSm,
            underMd && classes.underMd,
            aboveMd && classes.aboveMd,
          )}
        >
          <Hidden mdUp>
            <IconButton disableRipple className={classes.menuButton} onClick={handleOpenMenu}>
              <TextAlignJustify size={24} weight="bold" />
            </IconButton>
          </Hidden>
          <RouterLink underline="hover" to="/init">
            <img
              className={classes.logo}
              component="img"
              alt="logotipo"
              src={logoImg}
              height={underSm ? '32px' : '40px'}
            />
          </RouterLink>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  card: {
    cursor: 'pointer !important',
    backgroundColor: 'transparent !important'
  },
  emoji: {
    width: '28px',
    height: '28px'
  }
}))

export default useStyles

import { htmlFontSize } from './constants'

export const fonts = {
  htmlFontSize,
  fontFamily: 'Inter, sans-serif',
  h1: {
    fontWeight: '500',
    fontSize: '63px',
    lineHeight: '76.24px',
  },
  h2: {
    fontWeight: '700',
    fontSize: '38px',
    lineHeight: '45.99px',
  },
  h3: {
    fontWeight: '700',
    fontSize: '30px',
    lineHeight: '38.73px',
  },
  h4: {
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '32.16px',
  },
  h5: {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '24.2px',
  },
  h5Regular: {
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24.2px',
  },
  h6: {
    fontWeight: '400',
    fontSize: '19px',
    lineHeight: '22.99px',
  },
  button: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19.36px',
  },
  subtitle1: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14.42px',
  },
  body1: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19.36px',
  },
  body2: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
  },
  subtitle2: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14.52px',
  },
  subtitle3: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19.36px',
  },
  caption: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14.52px',
  },
  label: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14.52px',
  },
  h1Mobile: {
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '26.63px',
  },
  h2Mobile: {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '21.44px',
  },
  h2MobileM: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '21.44px',
  },
  h3Mobile: {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '18.76px',
  },
  bodyMobileM: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14.52px',
  },
  bodyMobile: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '14.52px',
  },
  hiperLink: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '16.94px',
  },
  colectionMobile: {
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '13.4px',
  },
}

export default fonts

import { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Typography,
  Grid,
  Link,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { useTranslation } from 'hooks'
import GlobalContextProvider from 'context/global/GlobalContextProvider'
import clsx from 'clsx'
import { X } from 'phosphor-react'
import atlasDarkImg from 'assets/images/atlas_dark.svg'
import useStyles from './styles'

export const MainMenu = () => {
  const classes = useStyles()
  const t = useTranslation()
  const theme = useTheme()

  const underMd = useMediaQuery(theme.breakpoints.down('md'))
  const aboveSm = useMediaQuery(theme.breakpoints.up('sm'))
  const underSm = useMediaQuery(theme.breakpoints.down('sm'))

  const { isMainMenuOpen, setIsMainMenuOpen } = useContext(GlobalContextProvider)

  const handleDrawerClose = () => {
    setIsMainMenuOpen(false)
  }

  const onClickItem = () => {
    setIsMainMenuOpen(false)
  }

  return (
    <Drawer
      className={clsx(classes.root, underMd && aboveSm && 'underMd', underSm && 'underSm')}
      anchor="left"
      open={isMainMenuOpen}
      onClose={handleDrawerClose}
    >
      <Grid item container justifyContent="space-between" alignItems="center" sx={{ padding: '20px 32px 20px 32px' }}>
        <img component="img" alt="logotipo" src={atlasDarkImg} height={aboveSm ? '30' : '22'} />
        <IconButton onClick={handleDrawerClose}>
          <X size="24" weight="bold" />
        </IconButton>
      </Grid>

      <List className={clsx(classes.list, aboveSm ? 'aboveSm' : 'underSm')}>
        <Link component={RouterLink} underline="none" to={'/faq'} onClick={onClickItem}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText
                primary={<Typography variant={aboveSm ? 'h5' : 'h2Mobile'}>{t('side_menu:FAQ')}</Typography>}
              />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link component={RouterLink} underline="none" to={'/assist'} onClick={onClickItem}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText
                primary={
                  <Typography variant={aboveSm ? 'h5' : 'h2Mobile'}>{t('side_menu:technical_assistance')}</Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Drawer>
  )
}

export default MainMenu

import { createTheme } from '@mui/material/styles'
import palette from 'theme/base/palette'

const defaultTheme = createTheme()

const dataGrid = {
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: palette.neutral.white,
          borderRadius: '12px',
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
          },
          '.MuiDataGrid-row': {
            '& .MuiDataGrid-cell.index': {
              color: palette.neutral.main,
            },
          },
          '& .MuiDataGrid-row:hover': {
            '&:hover': {
              transition: defaultTheme.transitions.create(['background'], {
                easing: defaultTheme.transitions.easing.sharp,
                duration: defaultTheme.transitions.duration.shorter,
              }),
              backgroundColor: palette.primary.hover,
              cursor: 'pointer',
              '& .MuiDataGrid-cell': {
                color: palette.primary.dark,
              },
            },
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: palette.primary.hover,
          },
        },
      },
    },
  },
}

export default dataGrid

import { useCallback, useEffect, useState, useContext } from 'react'
import { matchRoutes } from 'react-router-dom'
import { ROUTES } from 'config'
import GlobalContextProvider from 'context/global/GlobalContextProvider'

export const useShowBreadCrumb = () => {
  const { setShowBreadCrumb, setDataBreadCrumb } = useContext(GlobalContextProvider)
  const [currentRoute, setCurrentRoute] = useState(undefined)

  useEffect(() => {
    const config = ROUTES?.find(r => r.path === currentRoute)
    setShowBreadCrumb(config?.showBreadCrumb)
    setDataBreadCrumb(config?.navigation)
  }, [currentRoute])

  const setRouteShowBreadCrumb = useCallback(location => {
    const match = matchRoutes(ROUTES, location)
    if (match !== null) {
      const path = match[0]?.route?.path
      setCurrentRoute(path)
    } else {
      setCurrentRoute(null)
    }
  }, [])

  return { setRouteShowBreadCrumb }
}

export default useShowBreadCrumb

import React from 'react'
import PropTypes from 'prop-types'
import ErrorControllerPage from './errorControllerPage'
import ErrorControllerSection from './errorControllerSection'
import ErrorControllerDialog from './errorControllerDialog'
import ErrorControllerIcon from './errorControllerIcon'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError || this.props.isExternalError) {
      return (
        <>
          {this.props.type === 'page' ? <ErrorControllerPage sx={this.props.sx} /> : null}
          {this.props.type === 'section' ? (
            <ErrorControllerSection sx={this.props.sx} size={this.props.size} isCard={this.props.isCard} />
          ) : null}
          {this.props.type === 'dialog' ? (
            <ErrorControllerDialog
              sx={this.props.sx}
              size={this.props.size}
              isOpen={this.props.isOpen}
              isCard={false}
              dialogTitle={this.props.dialogTitle}
              isFullScreen={this.props.isFullScreen}
              onClose={this.props.onClose}
            />
          ) : null}
          {this.props.type === 'icon' ? (
            <ErrorControllerIcon
              sx={this.props.sx}
              iconSize={this.props.iconSize}
              showText={this.props.showText}
              showTooltip={this.props.showTooltip}
            />
          ) : null}
        </>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  type: PropTypes.oneOf(['page', 'section', 'dialog', 'icon']).isRequired,
  iconSize: PropTypes.number,
  showText: PropTypes.bool,
  showTooltip: PropTypes.bool,
  isExternalError: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isCard: PropTypes.bool,
  isOpen: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  dialogTitle: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary

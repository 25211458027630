import { Grid, Box } from '@mui/material'
import { Cards, ErrorController } from 'ui'
import { Question, Headset } from 'phosphor-react'

export const InitView = () => {
  let cardsContent = [
    {
      nav: '/faq',
      title: 'card:title:faq',
      description: 'card:subtitle:have_questions',
      icon: <Question />,
    },
    {
      nav: '/assist',
      title: 'card:title:technical_support',
      description: 'card:subtitle:request_online_technical_support',
      icon: <Headset />,
    },
  ]

  return (
    <Box width={'100%'}>
      <Grid
        container
        direction="row"
        justifyContent={'center'}
        columns={{ xss: 4, xs: 4, sm: 8, md: 12, xl: 16, lg: 12 }}
        columnSpacing={{ xss: 2, xs: '0px' }}
        alignItems={'center'}
        mt={{ xss: '84px', xl: '93px', lg: '84px' }}
        mb={{ sm: '0px' }}
        p={{ xss: '0px 16px', sm: '0px 40px' }}
      >
        <ErrorController type="section" size="medium" isCard={true}>
          {cardsContent.map(({ nav, title, description, icon }, index) => (
            <Grid
              item
              key={index}
              xss={4}
              xs={4}
              sm={8}
              md={6}
              lg={6}
              xl={6}
              sx={{
                marginRight: { xss: '0px', md: '16px' },
                marginBottom: { xss: '16px' },
                maxWidth: { md: '100%' },
                flexBasis: { md: '43.7%', lg: '45.61%', xl: '34.8%' },
                padding: '0px !important',
              }}
            >
              <Cards
                nav={nav}
                title={title}
                description={description}
                icon={icon}
                sx={{
                  height: { xss: '149px', xs: '149px', sm: '257px', md: '275px', xl: '257px', lg: '257px' },
                  maxHeight: { xss: '149px', xs: '149px', sm: '257px', md: '275px', xl: '257px', lg: '257px' },
                }}
              />
            </Grid>
          ))}
        </ErrorController>
      </Grid>
    </Box>
  )
}

export default InitView

import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Breadcrumbs, Link, Typography, useMediaQuery, useTheme, IconButton } from '@mui/material'
import { House, CaretRight } from 'phosphor-react'
import palette from 'theme/base/palette'
import clsx from 'clsx'
import useStyles from './styles'

export const Breadcrumb = ({ navigation }) => {
  const classes = useStyles()
  const theme = useTheme()
  const underSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Breadcrumbs
      separator={<CaretRight size={15} />}
      paddingLeft={{
        xss: '16px',
        xs: '16px',
        sm: '40px',
        md: '88px',
        lg: '88px',
        xl: '88px',
      }}
      paddingTop={'0px !important'}
    >
      <IconButton to="/" component={RouterLink} className={classes.iconButton}>
        <House size={24} />
      </IconButton>
      {navigation &&
        navigation?.map(breadcrumb =>
          breadcrumb.link !== null ? (
            <Link
              underline="hover"
              color={palette.primary.main}
              to={breadcrumb?.link}
              key={breadcrumb?.id}
              component={RouterLink}
            >
              <Typography
                className={clsx(classes.textContainer, underSm ? 'underSm' : '')}
                variant={underSm ? 'bodyMobileM' : 'body1'}
              >
                {breadcrumb?.icon && (
                  <span style={{ marginRight: '10px' }} className={clsx(classes.icon, underSm ? 'underSm' : '')}>
                    {breadcrumb?.icon}
                  </span>
                )}
                <span className={clsx(classes.text, underSm ? 'underSm' : '')}>{breadcrumb?.label}</span>
              </Typography>
            </Link>
          ) : (
            <Typography
              className={clsx(classes.textContainer, underSm ? 'underSm' : '')}
              key={breadcrumb?.id}
              variant={underSm ? 'bodyMobileM' : 'body1'}
            >
              {breadcrumb?.icon && (
                <span
                  style={{ marginRight: '10px', paddingTop: '7px' }}
                  className={clsx(classes.icon, underSm ? 'underSm' : '')}
                >
                  {breadcrumb?.icon}
                </span>
              )}
              <span className={clsx(classes.text, underSm ? 'underSm' : '')}>{breadcrumb?.label}</span>
            </Typography>
          ),
        )}
    </Breadcrumbs>
  )
}

export default Breadcrumb

Breadcrumb.propTypes = {
  navigation: PropTypes.array,
}

import { createTheme } from '@mui/material/styles'
const defaultTheme = createTheme()

const customShadows = [
  'none',
  '0px 0px 1px rgba(0, 69, 113, 0.12), 0px 1.6px 4px rgba(0, 69, 113, 0.12)',
  '0px 0px 1.8px rgba(0, 69, 113, 0.12), 0px 2.2px 7.2px rgba(0, 69, 113, 0.12)',
  '0px 4px 20px rgba(0, 69, 113, 0.12), 0px 0px 3.6px rgba(0, 69, 113, 0.12)',
]

export const shadows = defaultTheme.shadows.map((value, index) => {
  return customShadows[index] ? customShadows[index] : value
})

export default shadows

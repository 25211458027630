import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Typography } from '@mui/material'
import {
  Question,
  Plus,
  House,
  Minus,
  MagnifyingGlass,
  X,
  List,
  FileText,
  Calendar,
  Headset,
  ArrowCircleLeft,
  BugBeetle,
  Chat,
  Lightbulb,
  CloudArrowUp,
} from "phosphor-react";

import { ReactComponent as Serius } from "assets/icons/serius.svg";
import { ReactComponent as Sad } from "assets/icons/sad.svg";
import { ReactComponent as Smiling } from 'assets/icons/smiling.svg'


export default {
  title: 'Tokens/Icons',
  parameters: {
    status: 'beta',
    controls: { hideNoControlsWarning: true },
  },
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        color: theme.palette.primary.main,
      },
    },
  }),
)

export const Icons = () => {
  const classes = useStyles()

  return (
    <Box p={2}>
      <Typography variant="body2" gutterBottom>
        Phosphor React
      </Typography>
      <Box className={classes.root}>
        <Question size={32} />
        <Plus size={32} />
        <House size={32} />
        <Minus size={32} />
        <MagnifyingGlass size={32} />
        <X size={32} />
        <List size={32} />
        <FileText size={32} />
        <Calendar size={32} />
        <Headset size={32} />
        <ArrowCircleLeft size={32} />
        <BugBeetle size={32} />
        <Chat size={32} />
        <Lightbulb size={32} />
        <CloudArrowUp size={32}/>
      </Box>
      <Typography variant="body2" gutterBottom>
        Emoji
      </Typography>
      <Smiling style={{ width: 32, height: 32 }} />
      <Sad style={{ width: 32, height: 32 }} />
      <Serius style={{ width: 32, height: 32 }} />
    </Box>
  );
}


Icons.storyName = 'Icons'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { QueryClientProvider } from 'react-query'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as Emotion10ThemeProvider } from '@emotion/react'
import { AuthContextProvider } from './context/auth/AuthContextProvider'
import { GlobalContextProvider } from './context/global/GlobalContextProvider'
import { queryClient, NotistackWrapper } from 'utils'
import theme from './theme/theme'
import reportWebVitals from './reportWebVitals'
import TraductionsEs from './config/locales/es.json'
import { es } from 'date-fns/locale'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <Emotion10ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <NotistackWrapper>
              <BrowserRouter>
                <IntlProvider locale="es" messages={TraductionsEs}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} defaultLocale={es} adapterLocale={es}>
                    <AuthContextProvider>
                      <GlobalContextProvider>
                        <App />
                      </GlobalContextProvider>
                    </AuthContextProvider>
                  </LocalizationProvider>
                </IntlProvider>
              </BrowserRouter>
            </NotistackWrapper>
          </ThemeProvider>
        </StyledEngineProvider>
      </Emotion10ThemeProvider>
    </QueryClientProvider>
  </>,
)

reportWebVitals()

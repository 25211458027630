import { QueryClient } from 'react-query'

export const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: 'always',
    },
    mutations: {
      retry: 0,
    },
  },
}

export const queryClient = new QueryClient(queryClientConfig)

import { createTheme } from '@mui/material/styles'
import fonts from 'theme/base/fonts'
import palette from 'theme/base/palette'

const defaultTheme = createTheme()

const tooltip = {
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.primary.main,
          borderRadius: 8,
          ...fonts.label,
        },
        arrow: {
          color: palette.primary.main,
          fontSize: 8,
        },
        tooltipPlacementRight: {
          [defaultTheme.breakpoints.up('sm')]: {
            margin: '0 4px',
          },
        },
      },
    },
  },
}

export default tooltip

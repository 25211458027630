import { createTheme } from '@mui/material/styles'
import fonts from 'theme/base/fonts'
import palette from 'theme/base/palette'

const defaultTheme = createTheme()

const avatar = {
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: defaultTheme.spacing(4),
          height: defaultTheme.spacing(4),
          ...fonts.subtitle1,
          fontWeight: 'bold',
          '&.medium': {
            width: defaultTheme.spacing(8),
            height: defaultTheme.spacing(8),
            ...fonts.body1,
            fontWeight: 'bold',
          },
          '&.large': {
            width: defaultTheme.spacing(18),
            height: defaultTheme.spacing(18),
            ...fonts.h1,
          },
          '&.small': {
            width: defaultTheme.spacing(3),
            height: defaultTheme.spacing(3),
          },
          '&.MuiAvatar-rounded': {
            borderRadius: '12px !important',
          },
        },
        colorDefault: {
          backgroundColor: palette.primary.light,
          color: palette.primary.main,
        },
      },
    },
  },
}

export default avatar

import communication from 'utils/communication'
import axios from 'axios'

const REACT_APP_ATLAS = process.env.REACT_APP_ATLAS_CONECTION
const ATLAS_DIRECTORY = window?.ApplicableDirectory?.apiCliklup || process.env.REACT_APP_API_ATLAS_V2_DIR

export const postContactUs = async data => {
  return communication.post(`${REACT_APP_ATLAS}/${ATLAS_DIRECTORY}`, `/asistencia-tecnica/contacta`, data)
}

export const postRequestFuncionality = async ({ asunto, descripcion, adjunto }) => {
  const formData = new FormData()
  formData.append('asunto', asunto)
  formData.append('descripcion', descripcion)
  formData.append('adjunto', adjunto)

  return axios.post(`${REACT_APP_ATLAS}/${ATLAS_DIRECTORY}/asistencia-tecnica/solicita-funcionalidad`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const postReportError = async ({ asunto, descripcion, tipo_problema, adjunto }) => {
  const formData = new FormData()
  formData.append('asunto', asunto)
  formData.append('descripcion', descripcion)
  formData.append('tipo_problema', tipo_problema)
  formData.append('adjunto', adjunto)

  return axios.post(`${REACT_APP_ATLAS}/${ATLAS_DIRECTORY}/asistencia-tecnica/reporta-error`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

import { useState, useContext, useEffect } from 'react'
import GlobalContextProvider from 'context/global/GlobalContextProvider'
import { useAddNewContactUs } from 'services'
import { useCheckEmptyForm } from 'hooks'
import { DELAY_ACTION } from 'config/constantsApp'

export const useContactForm = () => {
  const addNewContactUs = useAddNewContactUs()
  const isEmptyForm = useCheckEmptyForm()
  const [formState, setFormState] = useState(undefined)
  const [formTouched, setFormTouched] = useState(undefined)

  const { isSuccessForm, setIsSuccessForm } = useContext(GlobalContextProvider)

  useEffect(() => {
    if (isSuccessForm === true) {
      resetForm()
    }
  }, [isSuccessForm])

  const onChangeValueState = ({ field, value }) => {
    setFormState({ ...formState, [field]: value })
  }

  const onChangeTouched = ({ field }) => {
    setFormTouched({ ...formTouched, [field]: true })
  }

  const resetForm = () => {
    setTimeout(() => {
      setFormState(undefined)
      setFormTouched({})
    }, DELAY_ACTION)
  }

  const handleSubmit = async () => {
    setIsSuccessForm(false)

    const data = {
      descripcion: formState?.descripcion_contacto?.trim() || null,
    }

    if (isEmptyForm(data)) {
      return
    }

    addNewContactUs.mutate(data)
  }

  return {
    formState,
    setFormState,
    handleSubmit,
    onChangeValueState,
    onChangeTouched,
    addNewContactUs,
  }
}

export default useContactForm

import PropTypes from 'prop-types'
import { Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'hooks'
import { useFrequentQuestionQuery } from 'services'
import { ErrorController, FaqAccordion, Cards } from 'ui'
import { Headset } from 'phosphor-react'

export const FAQView = () => {
  const t = useTranslation()
  const theme = useTheme()
  const smallUp = useMediaQuery(theme.breakpoints.up('sm'))

  const { frequentQuestionData, isFrequentQuestionLoading } = useFrequentQuestionQuery({ hardLoad: false })

  const card_content = {
    nav: '/assist',
    title: 'card:title:technical_support',
    description: 'card:subtitle:request_online_technical_support',
    icon: <Headset />,
  }

  return (
    <Box width={'100%'}>
      <Grid container columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }} justifyContent="flex-start">
        <Grid container justifyContent="center" alignContent="center" mb={5}>
          <ErrorController type="icon" iconSize={32}>
            <Typography variant={smallUp ? 'h3' : 'h1Mobile'} align="center">
              {t('faq_view:title:frequently_asked_questions')}
            </Typography>
          </ErrorController>
        </Grid>
        <Grid
          container
          justifyContent="center"
          columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }}
          paddingRight={{ xss: '16px', xs: '16px', sm: '40px', md: '88px', lg: '88px', xl: '88px' }}
          paddingLeft={{ xss: '16px', xs: '16px', sm: '40px', md: '88px', lg: '88px', xl: '88px' }}
        >
          <Grid item xl={12} lg={10} md={12} sm={11} xs={12}>
            <ErrorController type="section" size="medium" isCard={true}>
              <FaqAccordion data={frequentQuestionData} isLoading={isFrequentQuestionLoading} />
            </ErrorController>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }}
          paddingRight={{ xss: '16px', xs: '16px', sm: '40px', md: '88px', lg: '88px', xl: '88px' }}
          paddingLeft={{ xss: '16px', xs: '16px', sm: '40px', md: '88px', lg: '88px', xl: '88px' }}
          marginTop={{ xss: '36px', xs: '36px', sm: '50px', md: '41px', lg: '36px', xl: '34px' }}
        >
          <Grid item xss={4} sm={8} md={12} lg={12} xl={16} marginBottom={'8px'}>
            <ErrorController type="icon" iconSize={32}>
              <Typography variant={smallUp ? 'h3' : 'h1Mobile'} component="h3" align="center">
                {t('faq_view:title:still_have_questions')}
              </Typography>
            </ErrorController>
          </Grid>
          <Grid
            item
            xss={4}
            sm={8}
            md={12}
            lg={12}
            xl={16}
            marginBottom={{ xss: '30px', xs: '30px', sm: '42px', md: '33px', lg: '28px', xl: '26px' }}
          >
            <ErrorController type="icon" iconSize={32}>
              <Typography
                sx={{
                  typography: {
                    xss: 'bodyMobileM',
                    xs: 'bodyMobileM',
                    sm: 'h5Regular',
                    md: 'h5Regular',
                    lg: 'h5Regular',
                    xl: 'h5Regular',
                  },
                }}
                component="p"
                align="center"
              >
                {t('faq_view:subtitle:we_are_here_to_help_contact_technical_support_or_search_for_support_articles')}
              </Typography>
            </ErrorController>
          </Grid>
          <Grid
            item
            xss={4}
            sm={8}
            md={6}
            lg={6}
            xl={6}
            marginBottom={{ xss: '30px', xs: '30px', sm: '42px', md: '33px', lg: '28px', xl: '26px' }}
            justifyContent={'center'}
            display={'flex'}
          >
            <ErrorController type="section" size="medium" isCard={true}>
              <Cards
                nav={card_content.nav}
                title={card_content.title}
                description={card_content.description}
                icon={card_content.icon}
                sx={{
                  width: { xl: '644px', lg: '624px', md: '424px', sm: '640px', xs: '328px' },
                  maxWidth: { xl: '644px', lg: '624px', md: '424px', sm: '640px', xs: '328px' },
                  minHeight: { xl: '257px', lg: '257px', md: '275px', sm: '257px', xs: '149px' },
                }}
              />
            </ErrorController>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

FAQView.propTypes = {
  data: PropTypes.array,
}

export default FAQView

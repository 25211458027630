export const fetchSessionKey = 'FETCH_SESSION'
export const fetchAccountKey = 'FETCH_ACCOUNT'
export const fetchClickupKey = 'FETCH_CLICKUP'
export const fetchClickupCode = 'FETCH_CLICKUP_CODE'
export const fetchServicesKey = 'FETCH_SERVICES'
export const fetchArticlesKey = 'FETCH_ARTICLES'
export const fetchCollectionsKey = 'FETCH_COLLECTIONS'
export const fetchMenuCollectionsKey = 'FETCH_MENU_COLLECTIONS'
export const fetchMenuCollectionKey = 'FETCH_MENU_COLLECTION'
export const fetchArticleKey = 'FETCH_ARTICLE'
export const fetchGetCollectionKey = 'FETCH_GET_COLLECTION'
export const fetchGetFrequentQuestionsKey = 'FETCH_GET_FREQUENT_QUESTION'
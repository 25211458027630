import { createTheme } from '@mui/material/styles'

const defaultTheme = createTheme()

const stepper = {
  props: {
    MuiStepper: {
      orientation: 'vertical',
    },
  },
  components: {
    MuiStepper: {
      styleOverrides: {
        root: {
          position: 'fixed',
          top: '50%',
          right: defaultTheme.spacing(3),
          transform: 'translateY(-50%)',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        vertical: {
          padding: defaultTheme.spacing(1, 0),
        },
      },
    },
  },
}

export default stepper

import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from 'ui/atoms/circularProgress'
import { Box, Button as MuiButton } from '@mui/material'
import useStyles from './styles'

const ButtonShiny = ({ children, loading = false, ...props }) => {
  const { disabled } = props
  const classes = useStyles()
  const buttonRef = useRef(null)
  const maskRef = useRef(null)

  useEffect(() => {
    const button = buttonRef.current
    const mask = maskRef.current

    button.addEventListener('mousemove', e => {
      let xAxis = e.clientX - button.getBoundingClientRect().left
      let yAxis = e.clientY - button.getBoundingClientRect().top

      mask.style.opacity = 1
      mask.style.transform = `translate3d(calc(-50% + 40px + ${xAxis}px), calc(-50% + 65px + ${yAxis}px), 0)`
    })

    button.addEventListener('mouseleave', () => {
      mask.style.opacity = 0
    })
  }, [])

  useEffect(() => {
    if (disabled === true) {
      const mask = maskRef.current
      mask.style.opacity = 0
    }
  }, [disabled])

  return (
    <MuiButton ref={buttonRef} className={classes.button} variant="contained" disabled={loading} {...props}>
      <Box ref={maskRef} className={classes.mask} />
      <Box component="span" className={classes.content}>
        {children}
      </Box>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </MuiButton>
  )
}

ButtonShiny.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default ButtonShiny

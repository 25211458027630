import fonts from 'theme/base/fonts'
import palette from 'theme/base/palette'
import { alpha } from '@mui/material/styles'

const menu = {
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          minWidth: 200,
          marginTop: '8px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...fonts.body1,
          color: `${palette.neutral.dark} !important`,
          height: '35px !important',
          '&:hover': {
            backgroundColor: `${alpha(palette.primary.light, 0.2)} !important`,
            color: `${palette.primary.main} !important`,
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent !important',
            color: `${palette.primary.main} !important`,
          },
          '&.Mui-selected:hover': {
            backgroundColor: `${alpha(palette.primary.light, 0.2)} !important`,
          },
        },
      },
    },
  },
}

export default menu

import { useParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, Hidden, Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useMenuCollectionsQuery, useArticlesData } from 'services'
import { SupportComplexCard, SupportMenu } from 'ui'

export const SupportArticlesList = props => {
  const { idColeccion } = useParams()

  const { menuCollectionsData } = useMenuCollectionsQuery({
    hardLoad: false,
  })

  const { articlesData } = useArticlesData({
    idColeccion: idColeccion,
    hardLoad: false,
  })
  const coleccionData = articlesData?.metadatos?.coleccion
  const articlesList = articlesData?.informacion

  const theme = useTheme()
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'))
  const menuWidth = '283px'

  const navigate = useNavigate()
  const readMore = (event, idArticulo) => {
    navigate(`/support-collection/${idColeccion}/support-articles/${idArticulo}`)
  }

  return (
    <Box>
      <Grid container columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }} justifyContent={'center'}>
        <Hidden smDown mdDown>
          <Grid
            container
            direction="row"
            justifyContent={'flex-start'}
            alignItems="center"
            columns={{ md: 12, lg: 12, xl: 16 }}
            paddingRight={{
              xs: '16px',
              sm: '40px',
              md: '88px',
              lg: '88px',
              xl: '88px',
            }}
            paddingLeft={{
              xs: '16px',
              sm: '40px',
              md: '88px',
              lg: '88px',
              xl: '92px',
            }}
          >
            <Grid item xl={4} ml={24}>
              {/* <Breadcrumb
                current={coleccionData?.nombre}
                iconbreadcrumb={null}
                navigation={LINKS}
              /> */}
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          container
          zIndex="3"
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          columns={{ md: 12, lg: 12, xl: 16 }}
          paddingRight={{
            xs: '16px',
            sm: '40px',
            md: '88px',
            lg: '88px',
            xl: '88px',
          }}
          paddingLeft={{
            xs: '16px',
            sm: '40px',
            md: '88px',
            lg: '88px',
            xl: '92px',
          }}
        >
          <Hidden smDown mdDown>
            <Grid item>
              <SupportMenu data={menuCollectionsData} />
            </Grid>
          </Hidden>
        </Grid>
        <Grid
          container
          justifyContent="center"
          columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }}
          paddingRight={{
            xs: '16px',
            sm: '40px',
            md: '40px',
            lg: '88px',
            xl: '88px',
          }}
          paddingLeft={{
            xs: '16px',
            sm: '40px',
            md: menuWidth,
            lg: menuWidth,
            xl: menuWidth,
          }}
          marginTop={{
            md: '31px',
            lg: '30px',
            sm: '27px',
            xs: '23px',
            xl: '23px',
          }}
        >
          <Grid item container direction="row" justifyContent="center">
            <Typography variant={'h3'}>{coleccionData?.nombre}</Typography>
          </Grid>
          {xlUp ? (
            <Grid
              container
              justifyContent={'center'}
              columnSpacing={2}
              xl={12}
              marginTop={{
                md: '30px',
                lg: '30px',
                sm: '27px',
                xs: '24px',
                xl: '30px',
              }}
            >
              {articlesList?.map(articulo => {
                return (
                  <>
                    <Grid item xl={5} paddingBottom={4}>
                      <SupportComplexCard
                        key={articulo?.id}
                        coleccion={articulo?.coleccion_nombre.toUpperCase()}
                        introduccion={articulo?.resumen}
                        usuario_nombre={articulo?.usuario_nombre}
                        titulo={articulo?.titulo}
                        usuario_foto={articulo?.usuario_foto}
                        readMore={event => {
                          readMore(event, articulo?.id)
                        }}
                      ></SupportComplexCard>
                    </Grid>
                  </>
                )
              })}
            </Grid>
          ) : (
            <Grid
              container
              justifyContent={'center'}
              columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }}
              marginTop={{
                md: '30px',
                lg: '30px',
                sm: '27px',
                xs: '24px',
                xl: '30px',
              }}
            >
              {menuCollectionsData?.map(item => {
                return (
                  <>
                    {item?.articulos.map(articulo => {
                      return (
                        <>
                          <Grid item lg={10} md={12} sm={8} xs={4} paddingBottom={4}>
                            <SupportComplexCard
                              key={articulo?.id}
                              coleccion={articulo?.coleccion_nombre.toUpperCase()}
                              introduccion={articulo?.resumen}
                              usuario_nombre={articulo?.usuario_nombre}
                              titulo={articulo?.titulo}
                              usuario_foto={articulo?.usuario_foto}
                              readMore={event => {
                                readMore(event, articulo?.id)
                              }}
                            />
                          </Grid>
                        </>
                      )
                    })}
                  </>
                )
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

SupportArticlesList.propTypes = {
  data: PropTypes.node,
  loading: PropTypes.string,
}

export default SupportArticlesList

import React from "react"
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from "prop-types"
import { useMediaQuery, useTheme, Card, Typography, Link, CardMedia, CardContent, Tooltip } from "@mui/material"
import TextOverflow from "ui/atoms/textOverflow"
import useTranslation from "hooks/useTranslation"
import { IconContext } from "phosphor-react";

const LinkRouter = props => <Link {...props} component={RouterLink} />

export const Cards = ({ title, description, icon, nav, sx }) => {
  const t = useTranslation()
  const theme = useTheme();
  const smallDown = useMediaQuery(theme.breakpoints.up("lg"));

  let hideDescription = false;
  description === 'form:input:description' ? hideDescription = true : hideDescription = false;

  return (
    <IconContext.Provider
      value={smallDown ? {
        size: 110.25,
        weight: "thin",
      } : {
        size: 90,
        weight: "thin",
      }}
    >

      <LinkRouter underline="hover" color={"transparent"} to={nav}>
        <Card
          variant="outlined"
          sx={{
            ...sx,
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: "center",
            boxShadow: `0px 1px 4px rgba(13, 23, 69, 0.2)`,
            ':hover': {
              backgroundColor: 'primary.hover'
            },
            ':active': {
              backgroundColor: 'primary.hover',
              borderColor: 'primary.main500',
              borderWidth: '2px'
            },
          }}

        >
          <CardMedia>
            {icon}
          </CardMedia>
          <CardContent
            style={{ 
              textAlign: 'center',
              width: '100%',
              padding: '0px 16px'
            }}
          >
            <Tooltip title={t(title)}>
              <span>
                <TextOverflow variant="h4" lines={2} sx={{lineHeight: '29px', marginBottom: '8px'}}>
                  {t(title)}
                </TextOverflow>
              </span>
            </Tooltip>

            {!hideDescription ? ( 
                <Typography variant="body1" sx={{ display: {xl: 'block', lg: 'block', md: 'block', sm: 'block', xs: 'none', xss: 'none' } }}>
                  {t(description)}
                </Typography> 
            ) : ( 
                <Typography variant="body1" sx={{ display: {xl: 'none', lg: 'none', md: 'none', sm: 'none', xs: 'none', xss: 'none' } }}>
                  {t(description)}
                </Typography>  
            )}

          </CardContent>
        </Card>

      </LinkRouter>
    </IconContext.Provider >
  );
}

Cards.propTypes = {
  title: PropTypes.string,
  nav: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.element,
  sx: PropTypes.object
};

export default Cards


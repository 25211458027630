import { CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  circle: {
    stroke: 'url(#linearColors)',
  },
}))

export const GradientCircularProgress = props => {
  const classes = useStyles({})
  return (
    <>
      <svg width="0" height="0">
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor="#4549D2" />
          <stop offset="100%" stopColor="#09DBA4" />
        </linearGradient>
      </svg>
      <CircularProgress
        {...props}
        thickness={4}
        classes={{ circle: classes.circle }}
      />
    </>
  )
}

export default GradientCircularProgress

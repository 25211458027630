import { isValid, isDate, format, lightFormat, formatDistanceToNowStrict } from 'date-fns'
import { es } from 'date-fns/locale'
import { toUpper } from 'ramda'
import { BACKEND_DATE_FORMAT } from 'config/constantsApp'

export const getInitials = ({ name, surname }) => {
  name = name?.charAt(0)?.toString() ? name?.charAt(0)?.toString() : ''
  surname = surname?.charAt(0)?.toString() ? surname?.charAt(0)?.toString() : ''
  return toUpper(name + surname)
}

export const removeAccents = str => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const timeToNow = date => {
  if (!isValid(date)) return
  return formatDistanceToNowStrict(date, { locale: es })
}

export const convertValuetoCode = literal => {
  return literal
    ?.trim()
    ?.toLowerCase()
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.replace(/ /g, '_')
}

export const convertIndex = index => {
  return index + 1 < 10 ? '0' + (index + 1) : index + 1
}

export const formatToIsoDate = dateToFormat => {
  let date = dateToFormat
  if (!isDate(date)) {
    date = new Date(date)
  }
  if (!isValid(date)) return
  return format(date, BACKEND_DATE_FORMAT)
}

export const getStrFromDate = date => (isValid(date) ? lightFormat(date, 'dd/MM/yyyy') : '')

const getStrHyphenDate = date => (isValid(date) ? lightFormat(date, 'yyyy-MM-dd') : '')
const getDateFromStr = str => new Date(Number(str.slice(6)), Number(str.slice(3, 5)) - 1, Number(str.slice(0, 2)))

/**
 * Format from db format TO DateMask format
 * @param {String} 'AAAA-MM-DD'
 * @return {String} 'DD/MM/AAAA'
 */
export const formatDateForDB = str => (Boolean(str) ? getStrHyphenDate(getDateFromStr(str)) : null)

/**
 * Format from DateMask format to db format
 * @param {String} 'DD/MM/AAAA'
 * @return {String} 'AAAA-MM-DD'
 */
export const formatDateFromDB = str =>
  Boolean(str) ? str.slice(8) + '/' + str.slice(5, 7) + '/' + str.slice(0, 4) : ''

/**
 * Format from DateMask format to db format
 * @param {String} 'DD/MM'
 * @return {String} 'MM-DD'
 */
export const reverseDayMonthDate = str => str.slice(3).concat('-', str.slice(0, 2))
/**
 * Format from db format format to DateMask format
 * @param {String}  'MM-DD'
 * @return {String}  'DD/MM'
 */
export const reverseMonthDayDate = str => str.slice(3).concat('/', str.slice(0, 2))

/**
 * Format phone number in 3 digits
 * @param {String} str
 * @returns {String}
 */
export const formatPhoneNumber = str => {
  return str ? str.substring(0, 3) + ' ' + str.substring(3, 6) + ' ' + str.substring(6) : ''
}

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

import makeStyles from '@mui/styles/makeStyles'
import fonts from 'theme/base/fonts'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: '0px',
    marginBottom: '32px',
  },
  root: {
    border: `1px solid ${theme.palette.primary.light}`,
    boxSizing: ' border-box',
    borderRadius: '12px',
    padding: '8px 16px',
    fontFamily: 'Inter,sans-serif',
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    ...fonts.body1,
    '&.hasValue': {
      backgroundColor: theme.palette.primary.lighter,
      '&:hover': {
        backgroundColor: theme.palette.neutral.white,
      },
    },
    '&::placeholder': {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: theme.palette.neutral.main,
      opacity: '1' /* Firefox */,
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    '&:-ms-input-placeholder': {
      /* Internet Explorer 10-11 */
      color: theme.palette.neutral.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    '&::-ms-input-placeholder': {
      /* Microsoft Edge */
      color: theme.palette.neutral.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
  },
  label: {
    textAlign: 'left',
    marginBottom: '2px',
  },
}))

export default useStyles

import React, { useContext, useEffect, useMemo } from 'react'
import { useMatch, useLocation, useNavigate } from 'react-router-dom'
import { authConfig } from 'config'
import  GlobalContextProvider  from 'context/global/GlobalContextProvider'
import { tokenize, useServicesQuery, useAccountQuery, useSessionQuery } from 'services'
import { useTranslation, useAuth } from 'hooks'
import { Page, ErrorController, AccessView } from 'ui'
import { HeaderInterceptor, ErrorInterceptor } from 'utils'
import ClientOAuth2 from 'ClientOAuth2'

const App = () => {
  ErrorInterceptor()
  HeaderInterceptor()

  const t = useTranslation()
  const { search } = useLocation()
  const { login, isLogged, logout } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { refetchSession, session, isSessionSuccess } = useSessionQuery()
  const { refetchAccountData } = useAccountQuery({ hardLoad: false })
  const { setVersion } = useContext(GlobalContextProvider)

  let searchParams = new URLSearchParams(search)
  const authorizationCode = searchParams?.get('code')
  const isPasswordRoute = useMatch('/password/:hash')
  const isHashRoute = useMatch('/hash')
  const isHashValueRoute = useMatch('/hash/:hash')

  let authAction = null
  if (isPasswordRoute) {
    authAction = `password-${isPasswordRoute.params.hash}`
  }
  if (isHashRoute) {
    authAction = 'hash'
  }
  if (isHashValueRoute) {
    authAction = `password-${isHashValueRoute.params.hash}`
  }
  const oAuthClient = useMemo(() => new ClientOAuth2(authConfig, authAction), [authAction])
  const { refetchServices } = useServicesQuery()

  const getAppData = () => {
    refetchServices()
  }
  useEffect(() => {
    setVersion(process.env.REACT_APP_ATLAS_VERSION ? process.env.REACT_APP_ATLAS_VERSION : '')
  }, [])

  useEffect(() => {
    const getAuthorization = async () => {
      const url = await oAuthClient.getAuthorization()
      window.location.replace(url)
    }
    const getSessionToken = async () => {
      try {
        const tokenParams = oAuthClient.getTokenParams()
        const tokenizeResponse = await tokenize({
          code: authorizationCode,
          ...tokenParams,
        })

        login(tokenizeResponse['access_token'])
      } catch (e) {
        navigate('/error')
      }
    }

    const checkSessionToken = async () => {
      if (session !== null && isSessionSuccess) {
        if (session) {
          getAppData()
        } else {
          logout()
        }
      }
    }

    if (isPasswordRoute || isHashRoute || isHashValueRoute) {
      getAuthorization()
      return
    }

    if (isLogged) {
      refetchSession()
      checkSessionToken()
    } else {
      if (authorizationCode) {
        getSessionToken()
      } else {
        getAuthorization()
      }
    }
  }, [
    authorizationCode,
    navigate,
    isHashRoute,
    isPasswordRoute,
    oAuthClient,
    refetchAccountData,
    isLogged,
    login,
    logout,
    session,
    refetchSession,
    isSessionSuccess,
  ])

  useEffect(() => {
    const checkSessionToken = async () => {
      if (!isLogged) logout()
    }
    if (isLogged) {
      checkSessionToken()
    }
  }, [pathname, isLogged, logout])
  if (!isLogged) {
    return <AccessView text={`${t('load:msg:checking-credentials')}...`}></AccessView>
  }

  return (
    <ErrorController type="page">
      <Page />
    </ErrorController>
  )
}

export default App

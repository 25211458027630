const backdrop = {
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(13, 23, 69, 0.6);',
        },
      },
    },
  },
}

export default backdrop

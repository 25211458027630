import { useQuery } from 'react-query'
import { fetchMenuCollections, fetchMenuCollection } from 'services'
import { queryClient, fetchMenuCollectionsKey, fetchMenuCollectionKey } from 'utils'

export const useMenuCollectionsQuery = ({ hardLoad }) => {

  const {
    data: menuCollectionsData,
    isLoading: isMenuCollectionsLoading,
    isError: isMenuCollectionsError,
    isSuccess: isMenuCollectionsSuccess,
    refetch: refetchMenuCollections,
  } = useQuery(
    [fetchMenuCollectionsKey],
    async () => {
      const stateQueryAtlas = queryClient.getQueryState(fetchMenuCollectionsKey)
      if (stateQueryAtlas?.data && !hardLoad) {
        return stateQueryAtlas.data
      }
      const menuCollectionsData = await fetchMenuCollections()
      return menuCollectionsData?.informacion || []
    },
    { initialData: null, enabled: true },
  )

  return {
    menuCollectionsData,
    isMenuCollectionsLoading,
    isMenuCollectionsError,
    isMenuCollectionsSuccess,
    refetchMenuCollections,
  }
}

export const useMenuCollectionQuery = ({ hardLoad, idColeccion }) => {

  const {
    data: menuCollectionData,
    isLoading: isMenuCollectionLoading,
    isError: isMenuCollectionError,
    isSuccess: isMenuCollectionSuccess,
    refetch: refetchMenuCollection,
  } = useQuery(
    [fetchMenuCollectionKey],
    async () => {
      const stateQueryAtlas = queryClient.getQueryState(fetchMenuCollectionKey)
      if (stateQueryAtlas?.data && !hardLoad) {
        return stateQueryAtlas.data
      }
      const menuCollectionData = await fetchMenuCollection(idColeccion)
      return menuCollectionData.informacion || []
    },
    { initialData: null, enabled: true }
  );

  return {
    menuCollectionData,
    isMenuCollectionLoading,
    isMenuCollectionError,
    isMenuCollectionSuccess,
    refetchMenuCollection,
  };
};

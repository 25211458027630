import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative',
    overflow: 'hidden',
    color: theme.palette.neutral.white,
    background: 'radial-gradient(356.94% 903.63% at 87.24% 141.67%, #5457D5 0%, #09DBA4 100%)',
    borderRadius: theme.spacing(4),
    '& .MuiButton-startIcon': {
      position: 'relative',
      zIndex: 1,
    },
  },
  mask: {
    position: 'absolute',
    width: '100%',
    minWidth: 181,
    height: '100%',
    minHeight: 179,
    backgroundSize: '181px 179px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='181' height='179' viewBox='0 0 181 179' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg filter='url(%23filter0_f)'%3e%3ccircle cx='83' cy='83' r='35' fill='%233BF29D'/%3e%3c/g%3e%3cg filter='url(%23filter1_f)'%3e%3ccircle cx='115' cy='113' r='18' fill='%233BF29D'/%3e%3c/g%3e%3cdefs%3e%3cfilter id='filter0_f' x='0' y='0' width='166' height='166' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3e%3cfeFlood flood-opacity='0' result='BackgroundImageFix'/%3e%3cfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3e%3cfeGaussianBlur stdDeviation='24' result='effect1_foregroundBlur'/%3e%3c/filter%3e%3cfilter id='filter1_f' x='49' y='47' width='132' height='132' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3e%3cfeFlood flood-opacity='0' result='BackgroundImageFix'/%3e%3cfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3e%3cfeGaussianBlur stdDeviation='24' result='effect1_foregroundBlur'/%3e%3c/filter%3e%3c/defs%3e%3c/svg%3e")`,
    transition: 'opacity 300ms',
    opacity: 0,
    zIndex: 0,
  },
  content: {
    position: 'relative',
    zIndex: 1,
    color: theme.palette.common.white,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export default useStyles

import { useState, useEffect, useContext } from 'react'
import { useAddNewRequestFuncionality } from 'services/technicalAssistance/mutation.service'
import { DELAY_ACTION } from 'config/constantsApp'
import GlobalContextProvider from 'context/global/GlobalContextProvider'
import useCheckEmptyForm from 'hooks/useCheckEmptyForm'

const useFunctionForm = () => {
  const addNewResquestFuncionality = useAddNewRequestFuncionality()
  const isEmptyForm = useCheckEmptyForm()
  const [formState, setFormState] = useState(undefined)
  const [formTouched, setFormTouched] = useState(undefined)
  const [initialValues, setInitialValues] = useState(undefined)
  const [file, setFile] = useState(undefined)

  const { isSuccessForm, setIsSuccessForm } = useContext(GlobalContextProvider)

  useEffect(() => {
    setInitialValues({})
    setFormTouched({})
    setFormState({})
  }, [])

  useEffect(() => {
    if (isSuccessForm === true) {
      resetForm()
    }
  }, [isSuccessForm])

  const onChangeValueState = ({ field, value }) => {
    setFormState({ ...formState, [field]: value })
  }

  const onChangeTouched = ({ field }) => {
    setFormTouched({ ...formTouched, [field]: true })
  }

  const uploadFile = f => {
    setFile(f.target.files[0])
  }

  const resetFile = () => {
    setFile(undefined)
  }

  const resetForm = () => {
    setTimeout(() => {
      setFormState({})
      setInitialValues({})
      setFormTouched({
        asunto: false,
        descripcion: false,
        adjunto_nombre: false,
      })
    }, DELAY_ACTION)
  }

  const handleSubmit = async () => {
    setIsSuccessForm(false)

    const data = {
      asunto: formState?.asunto?.trim() || null,
      descripcion: formState?.descripcion?.trim() || null,
      adjunto: file || null,
    }

    if (isEmptyForm(data)) {
      return
    }

    addNewResquestFuncionality.mutate({ ...data })
  }

  return {
    formState,
    setFormState,
    handleSubmit,
    onChangeValueState,
    addNewResquestFuncionality,
    onChangeTouched,
    uploadFile,
    resetFile,
    initialValues,
    formTouched,
  }
}

export default useFunctionForm

import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField, InputAdornment, Tooltip, IconButton } from '@mui/material'
import { CircularProgress } from 'ui'
import useStyles from './styles'
import clsx from 'clsx'
import { XCircle } from 'phosphor-react'
import { ENTER_DELAY_TOOLTIP_FIELDS } from 'config/constantsApp'

const InputFile = ({
  label,
  id,
  name,
  type,
  value,
  onBlur,
  helperText,
  error,
  onChange,
  sx,
  required,
  isLoadingFile,
  onDeleteFile,
  disabled,
}) => {
  const classes = useStyles()

  const handleDeleteFile = () => {
    onDeleteFile()
  }

  useEffect(() => {
    onChange()
  }, [value])

  return (
    <>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              marginTop: '-10px !important',
            },
          },
        }}
        title={value ? value : ''}
        enterDelay={ENTER_DELAY_TOOLTIP_FIELDS}
        enterNextDelay={ENTER_DELAY_TOOLTIP_FIELDS}
      >
        <TextField
          label={label}
          id={id}
          name={name}
          autoComplete="off"
          variant="filled"
          value={value || ''}
          onBlur={onBlur}
          className={clsx(type === 'compact' ? 'compact' : '', value && !error && 'hasValue', classes.input)}
          helperText={helperText}
          error={error}
          inputProps={{ tabIndex: '-1' }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <>
                {isLoadingFile ? (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ) : (
                  <>
                    {value && (
                      <InputAdornment position="end" className={classes.adornment}>
                        <IconButton disabled={disabled} aria-label="delete file" onClick={handleDeleteFile} edge="end">
                          <XCircle size={24} className={classes.deleteIcon} />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                )}
              </>
            ),
          }}
          sx={sx}
          required={required}
          disabled={disabled}
        />
      </Tooltip>
    </>
  )
}

InputFile.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.object,
  required: PropTypes.bool,
  isLoadingFile: PropTypes.bool,
  onDeleteFile: PropTypes.func,
  disabled: PropTypes.bool,
}

export default InputFile

import { Box, Grid, Typography } from '@mui/material'
import RefreshIllustrationError from 'assets/images/errors/error_generico.svg'
import useTranslation from 'hooks/useTranslation'
import PropTypes from 'prop-types'
import useStyles from './styles'

const ErrorControllerPage = ({ sx }) => {
  const classes = useStyles()
  const t = useTranslation()
  return (
    <>
      <Box sx={sx} className={classes.pageMainContainer}>
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item mb={5}>
            <img src={RefreshIllustrationError} alt="Error" width="100%" />
          </Grid>
          <Grid item style={{ maxWidth: '1040px' }}>
            <Typography variant="h2" className={classes.pageMainContainerTitle}>
              {t('error_controller:page:msg:refresh')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

ErrorControllerPage.propTypes = {
  sx: PropTypes.object,
}

export default ErrorControllerPage

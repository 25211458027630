import PropTypes from 'prop-types'
import { Grid, Card, CardContent, CardActions, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'hooks'
import { Avatar, ShinyButton, TextOverflow } from 'ui'

export const SupportComplexCard = ({ id, introduccion, titulo, coleccion, usuario_nombre, usuario_foto, readMore }) => {
  const t = useTranslation()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Grid container direction="row" justifyContent={'center'} minHeight={'100%'}>
      <Card
        sx={
          smDown
            ? {
                flexGrow: '1',
                maxWidth: '837px',
                paddingLeft: '36px',
                paddingTop: '32px',
                paddingRight: '36px',
                paddingBottom: '32px',
              }
            : {
                flexGrow: '1',
                paddingLeft: '24px',
                paddingTop: '16px',
                paddingRight: '36px',
                paddingBottom: '18px',
              }
        }
      >
        <CardContent mb={2} sx={{ padding: '0px' }}>
          <Grid item>
            <Typography variant={smDown ? 'subtitle2' : 'colectionMobile'} mb={2}>
              {coleccion}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={smDown ? 'h4' : 'h3Mobile'}>{titulo}</Typography>
          </Grid>
          <Grid container mb={2} mt={1}>
            <Grid item>
              <Avatar size={'16px'} avatarURL={usuario_foto} />
            </Grid>

            <Grid item mt={1.5} ml={1}>
              <Typography variant="subtitle1">{usuario_nombre}</Typography>
            </Grid>
          </Grid>
          <Grid item minHeight={'40px'}>
            <TextOverflow lines={2}>
              {introduccion ? (
                <Typography variant={smDown ? 'body1' : 'bodyMobileM'} sx={{ padding: '0px' }}>
                  {introduccion}
                </Typography>
              ) : (
                <Typography variant="body1" sx={{ padding: '0px' }}>
                  &nbsp;
                </Typography>
              )}
            </TextOverflow>
          </Grid>
        </CardContent>
        <CardActions
          sx={
            smDown
              ? { justifyContent: 'flex-end', paddingTop: 7 }
              : {
                  justifyContent: 'flex-end',
                  paddingTop: '16px',
                }
          }
        >
          <ShinyButton onClick={readMore}>{t('support_view:button:read_more')}</ShinyButton>
        </CardActions>
      </Card>
    </Grid>
  )
}

SupportComplexCard.propTypes = {
  readMore: PropTypes.func,
  id: PropTypes.string,
  introduccion: PropTypes.string,
  titulo: PropTypes.string,
  coleccion: PropTypes.string,
  usuario_nombre: PropTypes.string,
  usuario_foto: PropTypes.string,
}

export default SupportComplexCard

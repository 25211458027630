import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'radial-gradient(231.95% 1339.02% at 93.4% 30.68%, #4547D2 0%, #09DBA4 39%)',
    boxShadow: 'none',
    height: '64px',
  },
  toolbar: {
    height: '64px',
  },
  aboveMd: {
    padding: '0 88px',
  },
  underMd: {
    padding: '0 40px',
  },
  underSm: {
    padding: '0 16px',
  },
  menuButton: {
    marginRight: '24px',
    color: theme.palette.neutral.white,
    '&:hover': {
      background: 'transparent',
    },
  },
  logo: {
    position: 'relative',
    top: '2px',
  },
}))

export default useStyles

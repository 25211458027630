import { createTheme } from '@mui/material/styles'
import palette from 'theme/base/palette'

const defaultTheme = createTheme()

const iconButton = {
  components: {
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(1),
          '&.Mui-focusVisible': {
            backgroundColor: palette.primary.light,
          },
        },
      },
    },
  },
}

export default iconButton

import React from "react"
import { Link as RouterLink } from 'react-router-dom'
import { Grid, Card, CardContent, Typography, Link, } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types"
import { IconContext } from "phosphor-react";
import Skeleton from "@mui/material/Skeleton";

const LinkRouter = props => <Link {...props} component={RouterLink} />


export const SupportCard = ({ title, icon, nav, isLoading}) => {
  const theme = useTheme();
  const smallUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <IconContext.Provider
      value={
        smallUp
          ? {
              weight: "thin",
              size: 109,
            }
          : {
              weight: "thin",
              size: 50,
            }
      }
    >
      {isLoading ? (
        <LinkRouter underline="hover" color={"transparent"} to={nav}>
          <Card sx={{paddingBottom: "30px"}}>
            <CardContent sx={{paddingTop: "64px"}}>
              <Grid
                container
                spacing={0.5}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={2}>
                  <Typography
                    variant={smallUp ? "h4" : "h1Mobile"}
                    textAlign={"center"}
                    gutterBottom
                  >
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {icon}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </LinkRouter>
      ) : (
        <Skeleton variant="rectangular" width={210} height={118}></Skeleton>
      )}
    </IconContext.Provider>
  );
}

SupportCard.propTypes = {
  title: PropTypes.string,
  nav: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.element,
  isLoading: PropTypes.bool
};

export default SupportCard


import { createTheme } from '@mui/material/styles'
import fonts from 'theme/base/fonts'
import palette from 'theme/base/palette'

const defaultTheme = createTheme()

const tabs = {
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${palette.primary.main}`,
          minHeight: '44px',
          height: '44px',
        },
        indicator: {
          borderRadius: 0,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          maxWidth: 'none',
          color: palette.neutral.dark,
          ...fonts.body2,
          fontWeight: 500,
          textTransform: 'none',
          '& .MuiBadge-root': {
            alignItems: 'center',
            '& .MuiBadge-badge': {
              position: 'static',
              marginLeft: defaultTheme.spacing(1),
              backgroundColor: palette.primary.light,
              color: palette.neutral.dark,
              transform: 'none',
              '&.MuiBadge-invisible': {
                display: 'none',
              },
            },
          },
          '&.Mui-focusVisible': {
            backgroundColor: palette.neutral.white,
            borderTopLeftRadius: '11px',
            borderTopRightRadius: '11px',
            color: palette.primary.main,
            '& .MuiBadge-badge': {
              color: palette.primary.main,
            },
          },
          ' &.Mui-selected': {
            backgroundColor: palette.neutral.white,
            borderTopLeftRadius: '11px',
            borderTopRightRadius: '11px',
            color: palette.primary.main,
            '& .MuiBadge-badge': {
              color: palette.primary.main,
            },
          },
          ' &:hover': {
            backgroundColor: palette.neutral.white,
            borderTopLeftRadius: '11px',
            borderTopRightRadius: '11px',
            color: palette.primary.main,
          },
          [defaultTheme.breakpoints.up('sm')]: {
            minWidth: 0,
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(2, 0),
        },
      },
    },
  },
}

export default tabs

import { useQuery } from 'react-query'
import { queryClient } from 'utils/reactQuery/queryClient'
import { fetchCollections, fetchArticles, fetchArticle } from 'services'
import { fetchCollectionsKey, fetchArticlesKey, fetchArticleKey } from 'utils'

export const useCollectionsQuery = ({ hardLoad }) => {

  const {
    data: collectionsData,
    isLoading: isCollectionsLoading,
    isError: isCollectionsError,
    isSuccess: isCollectionsSuccess,
    refetch: refetchCollections,
  } = useQuery(
    [fetchCollectionsKey],
    async () => {
      const stateQueryClickup = queryClient.getQueryState(fetchCollectionsKey)
      if (stateQueryClickup?.data && !hardLoad) {
        return stateQueryClickup.data
      }
      const collectionsData = await fetchCollections()
      return collectionsData?.informacion || []
    },
    { initialData: null, enabled: true },
  )

  return {
    collectionsData,
    isCollectionsLoading,
    isCollectionsError,
    isCollectionsSuccess,
    refetchCollections,
  }
}

export const useArticlesData = ({ hardLoad, idColeccion }) => {
  const {
    data: articlesData,
    isLoading: isArticlesLoading,
    isError: isArticlesError,
    isSuccess: isArticlesDataSuccess,
    refetch: refetchArticles,
  } = useQuery(
    [fetchArticlesKey, idColeccion],
    async () => {
      const stateQueryClickup = queryClient.getQueryState(
        [fetchArticlesKey, idColeccion]
      );
      if (stateQueryClickup?.data && !hardLoad) {
        return stateQueryClickup.data
      }
      const articlesData = await fetchArticles(idColeccion)
      return articlesData || []
    },
    { initialData: null, enabled: true }
  );

  return {
    articlesData,
    isArticlesLoading,
    isArticlesError,
    isArticlesDataSuccess,
    refetchArticles,
  };
}

export const useArticleData = ({ hardLoad, idColeccion, idArticulo }) => {
  const {
    data: articleData,
    isLoading: isArticleLoading,
    isError: isArticleError,
    isSuccess: isArticleDataSuccess,
    refetch: refetchArticle,
  } = useQuery(
    [fetchArticleKey, idColeccion, idArticulo],
    async () => {
      const stateQueryClickup = queryClient.getQueryState(
        [fetchArticleKey, idColeccion, idArticulo]
      );
      if (stateQueryClickup?.data && !hardLoad) {
        return stateQueryClickup.data;
      }
      const articleData = await fetchArticle(idColeccion, idArticulo)
      return articleData.informacion || [];
    },
    { initialData: null, enabled: true }
  );

  return {
    articleData,
    isArticleLoading,
    isArticleError,
    isArticleDataSuccess,
    refetchArticle,
  };

}

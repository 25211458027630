import { createTheme } from '@mui/material/styles'
import palette from 'theme/base/palette'

const defaultTheme = createTheme()

const list = {
  props: {
    MuiListItem: {
      disableripple: "true",
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          height: 42,
          '& .MuiIconButton-root': {
            fontSize: '1rem',
          },
        },
        divider: {
          borderBottom: `1px solid ${palette.neutral.light}`,
        },
        dense: {
          height: 35,
        },
        button: {
          color: palette.neutral.dark,
          '&:hover': {
            backgroundColor: palette.primary.lighter,
          },
          '&.Mui-selected': {
            backgroundColor: defaultTheme.palette.common.white,
            color: palette.primary.main,
            '&:hover': {
              backgroundColor: palette.primary.lighter,
            },
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 34,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '& .MuiTypography-body2': {
            color: palette.primary.dark,
          },
          '& .MuiTypography-subtitle1': {
            color: palette.neutral.dark,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          width: defaultTheme.spacing(3),
          height: defaultTheme.spacing(3),
          fontSize: defaultTheme.spacing(3),
          marginRight: defaultTheme.spacing(1),
          color: palette.neutral.dark,
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          '& .MuiIconButton-root': {
            color: palette.neutral.dark,
            '&:hover': {
              color: palette.primary.main,
            },
          },
        },
      },
    },
  },
}

export default list

import { Box, Grid, Typography, Card } from '@mui/material'
import { ReactComponent as ErrorWarning } from 'assets/icons/error_warning.svg'
import useTranslation from 'hooks/useTranslation'
import PropTypes from 'prop-types'
import useStyles from './styles'
import clsx from 'clsx'

const ErrorControllerSection = ({ sx, size, isCard }) => {
  const classes = useStyles()
  const t = useTranslation()

  const Content = () => {
    return (
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <ErrorWarning className={clsx(size, classes.sectionMainIcon)} />
        </Grid>
        <Grid item style={{ maxWidth: '1000px' }}>
          <Typography
            variant={size === 'medium' || size === 'small' ? 'h4Light' : 'h3'}
            className={classes.sectionMainContainerTitle}
          >
            {t('error_controller:section:msg:refresh')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {isCard ? (
        <Card sx={sx} className={clsx(size, classes.sectionMainContainerCard)}>
          <Content />
        </Card>
      ) : (
        <Box sx={sx} className={clsx(size, classes.sectionMainContainer)}>
          <Content />
        </Box>
      )}
    </>
  )
}

ErrorControllerSection.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isCard: PropTypes.bool,
  sx: PropTypes.object,
}

export default ErrorControllerSection

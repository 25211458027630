import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, Typography, useMediaQuery } from '@mui/material'
import IconButton from 'ui/atoms/buttons/iconButton'
import ErrorControllerSection from './errorControllerSection'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import { X } from 'phosphor-react'
import useStyles from './styles'

const ErrorControllerDialog = ({ sx, size, isOpen, dialogTitle, onClose, isFullScreen }) => {
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  return (
    <>
      <Dialog fullScreen={!isFullScreen ? fullScreen : isFullScreen} open={open} onClose={handleClose}>
        <div className={classes.dialogHeader}>
          <div className={classes.dialogHeaderTitle}>
            <Typography variant="h5">{dialogTitle}</Typography>
          </div>
          <div className={classes.dialogHeaderButtonClose}>
            <IconButton onClick={handleClose}>
              <X />
            </IconButton>
          </div>
        </div>
        <DialogContent>
          <ErrorControllerSection sx={sx} size={size} />
        </DialogContent>
      </Dialog>
    </>
  )
}

ErrorControllerDialog.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isOpen: PropTypes.bool,
  sx: PropTypes.object,
  dialogTitle: PropTypes.string,
  onClose: PropTypes.func,
  isFullScreen: PropTypes.bool,
}

export default ErrorControllerDialog

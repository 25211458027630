import { useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import GlobalContextProvider from 'context/global/GlobalContextProvider'
import { AppRouter } from 'router'
import { useShowBreadCrumb } from 'hooks'
import { Footer, Header, MainMenu, ErrorController, Breadcrumb } from 'ui'
import useStyles from './styles'
import clsx from 'clsx'

export const Page = () => {
  const { setRouteShowBreadCrumb } = useShowBreadCrumb()
  const location = useLocation()
  const classes = useStyles()

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('721'))

  const { dataBreadCrumb, showBreadCrumb } = useContext(GlobalContextProvider)

  useEffect(() => {
    setRouteShowBreadCrumb(location)
  }, [location])

  return (
    <Box className={classes.root}>
      <Box>
        <ErrorController type="icon" iconSize={32}>
          <Header />
        </ErrorController>
      </Box>
      <Grid
        item
        container
        justifyContent="flex-start"
        alignContent="flex-start"
        className={clsx(classes.mainContainer, smUp === false ? 'separator' : '')}
      >
        {smUp && (
          <ErrorController type="icon" iconSize={32}>
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="center"
              className={classes.breadcrumbContainer}
            >
              {showBreadCrumb && <Breadcrumb navigation={dataBreadCrumb} />}
            </Grid>
          </ErrorController>
        )}
        <ErrorController type="section" size="medium" isCard={true}>
          <AppRouter />
        </ErrorController>
        <ErrorController type="section" size="medium" isCard={true}>
          <Outlet />
        </ErrorController>
      </Grid>
      <ErrorController type="icon" iconSize={32}>
        <Footer />
      </ErrorController>
      <ErrorController type="icon" iconSize={32}>
        <MainMenu />
      </ErrorController>
    </Box>
  )
}

export default Page

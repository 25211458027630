import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  input: {
    '& .MuiInputBase-root.Mui-error': {
      borderColor: `${theme.palette.error.light} !important`,
    },
    '& input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  adornment: {
    marginRight: '5px',
  },
  deleteIcon: {
    color: theme.palette.neutral.dark,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

export default useStyles

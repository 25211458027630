import {
  Calendar,
  CirclesThreePlus,
  ClipboardText,
  IdentificationCard,
  MapTrifold,
  Users,
  Bell,
} from 'phosphor-react'
import { ReactComponent as IconPharmacy } from 'assets/icons/pharmacy.svg'
import { ReactComponent as IconParty } from 'assets/icons/party.svg'

export default {
  'clipboard-tex': <ClipboardText size={28} />,
  'identification-card': <IdentificationCard size={28} />,
  'icon-pharmacy': <IconPharmacy width={28} />,
  'icon-party': <IconParty width={28} />,
  'map-trifold': <MapTrifold size={28} />,
  'circles-three-plus': <CirclesThreePlus size={28} />,
  users: <Users size={28} />,
  calendar: <Calendar size={28} />,
  bell: <Bell size={28} />,
}

import makeStyles from '@mui/styles/makeStyles'
import { Typography as MuiTypography } from '@mui/material'
import palette from 'theme/base/palette'
import Link from '@mui/material/Link'

export default {
  title: 'Tokens/Typography',
  component: MuiTypography,
  parameters: {
    controls: { hideNoControlsWarning: true },
  },
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
    },
    color: palette.primary.dark,
  },
}))

export const Typography = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <MuiTypography variant="h1">Headline 1</MuiTypography>
      <MuiTypography variant="h2">Headline 2</MuiTypography>
      <MuiTypography variant="h3">Headline 3</MuiTypography>
      <MuiTypography variant="h4">Headline 4</MuiTypography>
      <MuiTypography variant="h5" component="div" gutterBottom>
        Headline 5
      </MuiTypography>
      <MuiTypography variant="h6" component="div" gutterBottom>
        Headline 6
      </MuiTypography>
      <MuiTypography variant="button" gutterBottom>
        Button
      </MuiTypography>
      <MuiTypography variant="subtitle1" component="div" gutterBottom>
        Subtitle1
      </MuiTypography>
      <MuiTypography variant="body1" component="div" gutterBottom>
        body1
      </MuiTypography>
      <MuiTypography variant="body2" component="div" gutterBottom>
        body2
      </MuiTypography>
      <Link variant="subtitle2" component="div" gutterBottom>
        Link
      </Link>
      <Link component="div" gutterBottom>
        Link (Hipervinculo)
      </Link>
      <MuiTypography variant="caption" component="div" gutterBottom>
        Caption
      </MuiTypography>
    </div>
  )
}

Typography.storyName = 'Typography'

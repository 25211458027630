import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { Grid, Hidden, Box } from '@mui/material'
import { useArticleData, useMenuCollectionsQuery } from 'services'
import { Breadcrumb, SupportArticlesCard, SupportMenu } from 'ui'
import { House } from 'phosphor-react'

export const SupportArticleView = () => {
  const { idColeccion, idArticulo } = useParams()
  const { articleData, refetchArticle } = useArticleData({
    idColeccion: idColeccion,
    idArticulo: idArticulo,
    hardLoad: false,
  })

  const { menuCollectionsData } = useMenuCollectionsQuery({ hardLoad: false })

  useEffect(() => {
    refetchArticle()
  }, [idColeccion])

  const menuWidth = '283px'
  //TODO añadir isLoading y skeleton

  const LINKS = [
    { label: <House size={24} />, navigate: { to: '/', search: null } },
    { label: 'Artículos de Apoyo', navigate: { to: '/support', search: null } },
    {
      label: `${articleData?.coleccion_nombre}`,
      navigate: {
        to: `/support-collection/${idColeccion}`,
        search: null,
      },
    },
  ]

  return (
    <Box>
      <Grid container columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }} justifyContent={'center'}>
        <Hidden smDown mdDown>
          <Grid
            container
            direction="row"
            justifyContent={'flex-start'}
            alignItems="center"
            columns={{ md: 12, lg: 12, xl: 16 }}
            paddingRight={{
              xs: '16px',
              sm: '40px',
              md: '88px',
              lg: '88px',
              xl: '88px',
            }}
            paddingLeft={{
              xs: '16px',
              sm: '40px',
              md: '88px',
              lg: '88px',
              xl: '92px',
            }}
          >
            <Grid item xl={12} lg={12} md={12} ml={24}>
              <Breadcrumb current={articleData?.titulo} iconbreadcrumb={null} navigation={LINKS}></Breadcrumb>
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          container
          zIndex="3"
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          columns={{ md: 12, lg: 12, xl: 16 }}
          paddingRight={{
            xs: '16px',
            sm: '40px',
            md: '88px',
            lg: '88px',
            xl: '88px',
          }}
          paddingLeft={{
            xs: '16px',
            sm: '40px',
            md: '88px',
            lg: '88px',
            xl: '92px',
          }}
        >
          <Hidden smDown mdDown>
            <Grid item>
              <SupportMenu data={menuCollectionsData} />
            </Grid>
          </Hidden>
        </Grid>
        <Grid
          container
          justifyContent="center"
          columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }}
          paddingRight={{
            xs: '16px',
            sm: '40px',
            md: '40px',
            lg: '88px',
            xl: '88px',
          }}
          paddingLeft={{
            xs: '16px',
            sm: '40px',
            md: menuWidth,
            lg: menuWidth,
            xl: menuWidth,
          }}
          marginTop={{
            md: '31px',
            lg: '30px',
            sm: '27px',
            xs: '23px',
            xl: '23px',
          }}
        >
          <Grid
            container
            justifyContent={'center'}
            columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }}
            columnSpacing={2}
            xl={12}
            marginTop={{
              md: '30px',
              lg: '30px',
              xl: '30px',
            }}
          >
            <Grid item xl={16} lg={10} md={12} sm={8} xs={4} paddingBottom={4}>
              <SupportArticlesCard
                id={articleData?.id}
                titulo={articleData?.titulo}
                contenido={<ReactMarkdown>{articleData?.contenido}</ReactMarkdown>}
                resumen={articleData?.subtitulo}
                coleccion={articleData?.coleccion_nombre.toUpperCase()}
                usuario_nombre={articleData?.usuario_nombre}
                usuario_foto={articleData?.usuario_foto}
              ></SupportArticlesCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SupportArticleView
